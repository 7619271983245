import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import classes from "./documentationSidebarMobile.module.css";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useCallback } from "react";
import { useEffect } from "react";
import { getCategoriesAPI, getDocsAPI } from "../../../services";

const DocumentationSidebarMobile = ({ toggleDrawer }) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [categories, setCategories] = useState([
    {
      id: 1,
      title: "jasdbauydbuysabduysabdysaudbasyudbasud",
      link: "sdasdsaadsdasd",
    },
  ]);
  const [documentations, setDocumentations] = useState([
    {
      id: 1,
      title:
        "dsadhasbdhjdbashdsajdvsayudasbduysavsddyuvsuayvdasuydvasudvadyuasvdayusvdasuyvdasyudas",
      link: "hdbsadusabdusabdasyudbsayu",
      categoryId: 1,
    },
  ]);

  const getAllDocsFromAPI = useCallback(async () => {
    try {
      const docsResponse = await getDocsAPI();
      const categoriesResponse = await getCategoriesAPI();
      setDocumentations(docsResponse.data);
      setCategories(categoriesResponse.data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getAllDocsFromAPI();
  }, [getAllDocsFromAPI]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleNavigation = (link) => {
    return () => {
      navigate(link);
      toggleDrawer()(false);
    };
  };

  return (
    <div className={`doc-sidebar-mobile-accordion ${classes.mainContainer}`}>
      {categories.map((category, index) => {
        const docs = documentations.filter(
          (doc) => parseInt(doc.categoryId) === parseInt(category.id)
        );

        if (docs.length > 0) {
          return (
            <Accordion
              key={index}
              disableGutters={true}
              expanded={expanded === category.id}
              onChange={handleChange(category.id)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.accordionSummary}
              >
                <p className={classes.categoryName}>{category.label}</p>
              </AccordionSummary>
              <AccordionDetails>
                {documentations.map(
                  (doc, index) =>
                    parseInt(doc.categoryId) === parseInt(category.id) && (
                      <p
                        onClick={handleNavigation(`/documentation/${doc.id}`)}
                        className={classes.documentationLink}
                      >
                        {doc.title}
                      </p>
                    )
                )}
              </AccordionDetails>
            </Accordion>
          );
        }
      })}
    </div>
  );
};

export default DocumentationSidebarMobile;
