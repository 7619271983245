import { Box } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import useCalendar from "./hook/useCalendar";
import useGetInfo from "./hook/useGetInfo";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const Calendar = ({ dateClickHandler, stepId }) => {
  const { setIsTimeLoading } = useGetInfo();

  const { getQueries } = useGetInfo();
  const { fetchData } = useGetInfo();
  const [date, setdate] = useState("");

  useEffect(() => {
    // setIsTimeLoading(true);
    const queries = getQueries();
    fetchData(queries + "&date=" + date);
    // setIsTimeLoading(false);

    console.log(date);
    // setIsLoading(true);
    // newSteps[4].options = res.data || newSteps[4].options;

    console.log("queries====", queries);
  }, [date]);

  const {
    calendarRows,
    selectedDate,
    todayFormatted,
    daysShort,
    monthNames,
    getNextMonth,
    getPrevMonth,
  } = useCalendar();

  const handelClick = (date) => {
    // setIsTimeLoading();
    // setIsTimeLoading(true);
    dateClickHandler(date);
    setdate(date);
    // setIsTimeLoading(false);
    // setIsTimeLoading(true);
    // console.log(date, info);
  };
  // const previousDate = (date) => {
  //   return(date > todayFormatted);
  // };
  return (
    <Fragment>
      <div className="Calender">
        <div className="controls">
          <Box onClick={getPrevMonth} display="flex" alignItems="center">
            <ArrowBackIosNewIcon />
          </Box>
          <h3 style={{ fontSize: "16px" }}>
            {`${
              monthNames[selectedDate.getMonth()]
            } - ${selectedDate.getFullYear()}`}
          </h3>
          <Box onClick={getNextMonth} display="flex" alignItems="center">
            <ArrowForwardIosIcon />
          </Box>
        </div>
        <table className="table">
          <thead>
            <tr>
              {daysShort.map((day) => (
                <th
                  key={day}
                  style={
                    day === "Sun" ? { color: "#F4EAE9" } : { color: "#4B64E9" }
                  }
                >
                  {day}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.values(calendarRows).map((cols, index) => {
              return (
                <tr key={cols[0].date}>
                  {cols.map((col) => {
                    return col.date === todayFormatted ? (
                      <td
                        key={col.date}
                        className={
                          cols[6].value === col.value
                            ? "offDay tableData today "
                            : ` ${col.classes}tableData today`
                        }
                        onClick={() => handelClick(col.date)}
                        style={
                          col.date === date
                            ? {
                                background: "#4B64E9",
                                color: "white",
                                cursor: "pointer",
                              }
                            : { cursor: "pointer" }
                        }
                      >
                        {col.value}
                      </td>
                    ) : (
                      <td
                        key={col.date}
                        className={
                          cols[6].value === col.value
                            ? "offDay tableData "
                            : ` ${col.classes} tableData`
                        }
                        onClick={() => handelClick(col.date)}
                        style={
                          col.date === date
                            ? {
                                background: "#4B64E9",
                                color: "white",
                                cursor: "pointer",
                              }
                            : { cursor: "pointer" }
                        }
                      >
                        {col.value}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default Calendar;
