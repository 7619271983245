import { Box, Checkbox, CircularProgress, Typography } from "@mui/material";
import React, { useRef } from "react";
import InfoIcon from "@mui/icons-material/Info";
import useGetInfo from "./hook/useGetInfo";

const ExtraService = ({ options, handelClick, stepId, isChecked }) => {
  const checkedInput = useRef(null);
  const { isLoading } = useGetInfo();

  return (
    <>
      {isLoading ? (
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          {options.map((option, index) => {
            const { id, name, price, duration } = option;
            return (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid #ddd",
                  margin: "10px",
                  padding: "4px 20px",
                  height: "60px",
                  cursor: "pointer",
                }}
                key={index}
                onClick={(e) =>
                  handelClick(
                    // option.serviceName,
                    option,
                    stepId,
                    "Extra Service",
                    null,
                    // option.price
                    price
                  )
                }
              >
                <Box
                  sx={{ display: "flex", flexDirection: "column", py: "4px" }}
                >
                  <Typography variant="p" fontSize="18px">
                    {/* {option.serviceName} */}
                    {name}
                  </Typography>
                  <Typography
                    variant="p"
                    fontSize="14px"
                    color="#939AB5"
                    sx={{
                      display: "flex",
                      // width: { xs: "80%", md: "300px" },
                      justifyContent: "space-between",
                      gap: "10px",
                    }}
                  >
                    {duration ? (
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Note
                        <InfoIcon
                          style={{
                            fontSize: "18px",
                            marginLeft: "5px",
                            cursor: "pointer",
                          }}
                        />
                      </span>
                    ) : (
                      ""
                    )}
                    {/* {option.serviceName} */}
                    <span>{duration}</span>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    color="primary"
                    paddingRight="5px"
                    whiteSpace="nowrap"
                  >
                    {/* {option.price ? `$${option.price}` : ""} */}
                    {price ? `$${price}` : ""}
                  </Typography>
                  <Box sx={{ borderLeft: "1px solid #ddd" }}>
                    <Checkbox
                      sx={{ padding: "0px", marginLeft: "1rem" }}
                      // checked={isChecked(option.serviceName, stepId)}
                      checked={isChecked(option, stepId)}
                      classes={{ root: "checkbox" }}
                    />
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      )}
    </>
  );
};

export default ExtraService;
