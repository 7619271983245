import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CustomTypography from "../../components/CustomTypography";
import CustomAutocomplete from "../../components/CustomAutocomplete";
import { TextField } from "@mui/material";
import resturant from "../../assets/images/subscribe/Restaurant-dinner-booking.jpg";
import classes from "./Blog.module.css";
import Chip from "../../components/Chip";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getBlogsAPI, getCategoriesAPI } from "../../services";
import { getImage } from "../../utils/getImage";
import moment from "moment/moment";
import { getPostDate } from "../../utils/getPostDate";
import H1Style from "../../components/textComponents/H1Style";
import { BACKEND_URL } from "../../constants/endpoint";
import parse from "html-react-parser";

const duration = [
  {
    duration: "0 Seats",
  },
  {
    duration: "15 Seats",
  },
  {
    duration: "30 Seats",
  },
  {
    duration: "45 Seats",
  },
  {
    duration: "1 Seats",
  },
];

const dummyBlogs = [
  {
    title:
      "News press title comes here, and if we have longer title let it be1",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint ea nisi veritatis tenetur deserunt, fuga quaerat non? Tempora recusandae accusamus totam! Doloremque praesentium perspiciatis temporibus esse, iure optio quas reiciendis!",
    date: "10 October 2022",
    perks: [
      { perk: "Up to 2 team member", description: "perks have descriptions" },
      {
        perk: "Up to 100 items",
        description: "perks have descriptionssssssssss",
      },
    ],
    categories: ["Category", "Second"],
  },
  {
    title:
      "News press title comes here, and if we have longer title let it be2",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint ea nisi veritatis tenetur deserunt, fuga quaerat non? Tempora recusandae accusamus totam! Doloremque praesentium perspiciatis temporibus esse, iure optio quas reiciendis!",
    date: "10 October 2022",
    perks: [
      { perk: "Up to 2 team member", description: "perks have descriptions" },
      {
        perk: "Up to 100 items",
        description: "perks have descriptionssssssssss",
      },
    ],
    categories: ["Category", "Second"],
  },
  {
    title:
      "News press title comes here, and if we have longer title let it be3",
    description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus qui natus, suscipit distinctio ipsum aliquid adipisci accusantium, temporibus alias nemo fugit inventore sapiente perferendis quis velit molestiae dicta! Aliquid necessitatibus, repudiandae fugiat tempora perspiciatis, similique consequatur cum nisi illo vitae deserunt a rerum! Quaerat at aperiam dignissimos quidem. Soluta sint praesentium nihil quidem unde natus, qui ullam, doloremque necessitatibus illo consequuntur aliquid nesciunt vitae aperiam est. Quasi nostrum quaerat dicta. Est pariatur repudiandae laudantium aut quam blanditiis quisquam magni facere totam, odit recusandae accusantium nihil in sequi aliquam maxime? Quod hic aspernatur esse, quaerat voluptatibus aliquid molestiae quisquam sint earum.`,
    date: "10 October 2022",
    perks: [
      { perk: "Up to 2 team member", description: "perks have descriptions" },
      {
        perk: "Up to 100 items",
        description: "perks have descriptionssssssssss",
      },
    ],
    categories: ["Category", "Second"],
  },
  {
    title:
      "News press title comes here, and if we have longer title let it be4",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint ea nisi veritatis tenetur deserunt, fuga quaerat non? Tempora recusandae accusamus totam! Doloremque praesentium perspiciatis temporibus esse, iure optio quas reiciendis!",
    date: "10 October 2022",
    perks: [
      { perk: "Up to 2 team member", description: "perks have descriptions" },
      {
        perk: "Up to 100 items",
        description: "perks have descriptionssssssssss",
      },
    ],
    categories: ["Category", "Second"],
  },
  {
    title:
      "News press title comes here, and if we have longer title let it be5",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint ea nisi veritatis tenetur deserunt, fuga quaerat non? Tempora recusandae accusamus totam! Doloremque praesentium perspiciatis temporibus esse, iure optio quas reiciendis!",
    date: "10 October 2022",
    perks: [
      { perk: "Up to 2 team member", description: "perks have descriptions" },
      {
        perk: "Up to 100 items",
        description: "perks have descriptionssssssssss",
      },
    ],
    categories: ["Category", "Second"],
  },
  {
    title:
      "News press title comes here, and if we have longer title let it be6",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint ea nisi veritatis tenetur deserunt, fuga quaerat non? Tempora recusandae accusamus totam! Doloremque praesentium perspiciatis temporibus esse, iure optio quas reiciendis!",
    date: "10 October 2022",
    perks: [
      { perk: "Up to 2 team member", description: "perks have descriptions" },
      {
        perk: "Up to 100 items",
        description: "perks have descriptionssssssssss",
      },
    ],
    categories: ["Category", "Second"],
  },
  {
    title:
      "News press title comes here, and if we have longer title let it be7",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint ea nisi veritatis tenetur deserunt, fuga quaerat non? Tempora recusandae accusamus totam! Doloremque praesentium perspiciatis temporibus esse, iure optio quas reiciendis!",
    date: "10 October 2022",
    perks: [
      { perk: "Up to 2 team member", description: "perks have descriptions" },
      {
        perk: "Up to 100 items",
        description: "perks have descriptionssssssssss",
      },
    ],
    categories: ["Category", "Second"],
  },
];

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sortedBy, setSortedBy] = useState("");

  const getBlogsFromAPI = useCallback(async () => {
    try {
      const blogsResponse = await getBlogsAPI();
      const categoriesResponse = await getCategoriesAPI();
      setCategories(categoriesResponse.data);
      setBlogs(blogsResponse.data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getBlogsFromAPI();
  }, [getBlogsFromAPI]);

  const handleSortedBy = (e, newValue) => {
    setSortedBy((prevState) => newValue);
  };

  return (
    <Grid
      item
      container
      sx={{
        minWidth: { xs: "100%", md: "auto" },
        justifyContent: "center",
        paddingLeft: {
          xs: "16px !important",
          sm: "40px !important",
          md: "40px !important",
        },
        paddingRight: {
          xs: "16px !important",
          sm: "40px !important",
          md: "40px !important",
        },
      }}
    >
      {/* first section */}
      <div className="body__container">
        <Box
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          <H1Style
            sx={{
              marginTop: 12,
              marginBottom: 2,
              // ml: { xs: 2, xl: 0 },
            }}
          >
            Blog - about everything
          </H1Style>
        </Box>

        <Grid
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            mx: { xs: 2, xl: 0 },
            p: { xs: "0 16px", md: "0" },
          }}
          mb={1}
          mt={4}
        >
          <Box sx={{ display: "flex" }}>
            <CustomTypography
              sx={{
                fontSize: 18,
                color: "#1976D2",
                fontWeight: "700",
              }}
            >
              {blogs.length}
            </CustomTypography>
            <CustomTypography
              sx={{
                fontSize: 18,
                color: "#1976D2",
                ml: 1,
              }}
            >
              result found
            </CustomTypography>
          </Box>
          {/* *
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "justify-between",
              width: { xs: "100%", md: "300px" },
              mr: { xs: 0, md: 2 },
              mt: { xs: 5, md: 0 },
            }}
          >
            <CustomTypography
              sx={{
                fontSize: 14,
                color: "#1976D2",
                width: "50%",
              }}
            >
              SORTED BY
            </CustomTypography>
            <CustomAutocomplete
              onChange={handleSortedBy}
              options={duration.map((option) => option.duration)}
              defaultValue={"Most popular"}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        fontWeight: "700",
                        color: "#3A3A50",
                      },
                    }}
                    placeholder="Select..."
                  />
                );
              }}
            />
          </Box> */}
        </Grid>

        <Box
          sx={{
            columnCount: { md: 3, xs: 1 },
            mx: { xs: 0, xl: 0 },
            gap: "25px",
          }}
        >
          {blogs.map((blogInfo, i) => {
            const {
              createdAt: date,
              title,
              description,
              image,
              categoryId,
            } = blogInfo;

            return (
              <Grid
                key={title}
                sx={{
                  display: "flex",
                  width: "100%",
                  pageBreakInside: "avoid",
                }}
              >
                <Box
                  sx={{
                    p: { xs: "25px 0px", md: "10px 0px 0 0" },
                    width: "100%",
                  }}
                >
                  <img
                    src={getImage(image ? `${BACKEND_URL + image}` : null)}
                    alt=""
                    className={classes.blog__image}
                  />
                  <CustomTypography
                    fontWeight={900}
                    fontSize={"30px"}
                    mb={"5px"}
                    variant="h1"
                  >
                    {title}
                  </CustomTypography>
                  <CustomTypography fontSize={"16px"} my={2}>
                    {getPostDate(date)}
                  </CustomTypography>
                  <Box sx={{ display: "flex", mb: 2 }}>
                    <Chip>
                      {
                        categories.find(
                          (category) =>
                            parseInt(category.id) === parseInt(categoryId)
                        )?.label
                      }
                    </Chip>
                  </Box>
                  <CustomTypography
                    sx={{
                      fontSize: "18px",
                      maxWidth: { md: "24vw", xs: "80vw" },
                      textwrap: "break-word",
                      overflowWrap: "break-word",
                      mb: 4,
                      img: {
                        maxWidth: "100%",
                      },
                    }}
                    variant="h4"
                  >
                    {parse(description)}
                  </CustomTypography>
                </Box>
              </Grid>
            );
          })}
        </Box>
      </div>
    </Grid>
  );
};

export default Blog;
