import { Box, Grid, TextField } from '@mui/material';
import React from 'react';
import CustomTypography from '../CustomTypography';
import H6Style from '../textComponents/H6Style';

function CustomTextField(props) {
  const customData = props.customData;

  return (
    <>
      <H6Style
        sx={{
          // color: "#1976D2 ",
          // fontSize: "16px",
          mb: '5px',
          ...customData?.labelSx,
        }}
      >
        {customData?.label}{' '}
        {props.required ? (
          <span
            style={{
              color: 'red',
            }}
          >
            *
          </span>
        ) : (
          ''
        )}
      </H6Style>
      <TextField
        inputProps={{
          style: {
            height: '18px',
            padding: 11,
          },
        }}
        helperText={props.error ? 'This field is required' : ' '}
        {...props}
        sx={{
          width: '100%',
          '& fieldset': { borderRadius: 1.5 },
          ...props.sx,
        }}
      ></TextField>
    </>
  );
}

export default CustomTextField;
