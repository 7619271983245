import { useCallback } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import DehazeIcon from '@mui/icons-material/Dehaze';
import { getArticleByLinkAPI } from '../../../services';
import classes from './documentationContent.module.css';

const DocumentationContent = ({ matches, toggleDrawer }) => {
  const [documentation, setDocumentation] = useState({
    title: '',
    description: '',
  });
  const { docId } = useParams(); // this is slug not an ID

  const getDocByLink = useCallback(async () => {
    try {
      const response = await getArticleByLinkAPI(docId);
      setDocumentation(response.data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getDocByLink();
  }, [getDocByLink]);

  return (
    <div
      className={
        matches ? classes.responsiveMainContainer : classes.mainContainer
      }
    >
      {matches && (
        <DehazeIcon
          onClick={toggleDrawer(true)}
          className={classes.responsiveMenuIcon}
        />
      )}
      <div className={classes.route}>
        <Link className={classes.helpCenterLink} to="/help-center">
          Documentation
        </Link>{' '}
        /
      </div>

      <h1 className={classes.docTitle}>{documentation.title}</h1>

      <div className={classes.docContainer}>
        {parse(documentation.description)}
      </div>
    </div>
  );
};

export default DocumentationContent;
