import { Box } from "@mui/system";
import React from "react";

function Chip(props) {
  return (
    <Box
      {...props}
      sx={{
        fontWeight: "700",
        backgroundColor: "#F7F8FB",
        width: "fit-content",
        px: 2,
        borderRadius: "15px",
        fontSize: "15px",
        boxShadow: "0px 0px 7px -3px rgba(0,0,0,0.63)",
        mr: 1,
        ...props.sx,
      }}
    >
      {props.children}
    </Box>
  );
}

export default Chip;
