import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CustomTypography from '../../components/CustomTypography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import collage1 from '../../assets/images/startPage/collage1.jpg';
import collage2 from '../../assets/images/startPage/collage2.jpg';
import collage3 from '../../assets/images/startPage/collage3.jpg';
import collage4 from '../../assets/images/startPage/collage4.jpg';
import collage5 from '../../assets/images/startPage/collage5.jpg';
import collage6 from '../../assets/images/startPage/collage6.jpg';
import classes from './StartPage.module.css';
import CustomTextField from '../../components/CustomTextField';
import CustomAutocomplete from '../../components/CustomAutocomplete';
import Chip from '../../components/Chip';
import { Divider, TextField, useMediaQuery } from '@mui/material';
import CustomButton from '../../components/CustomButton';
import upnizeServiceImg from '../../assets/img/Upnize-Service.svg';
import upnizeRestaurantImg from '../../assets/img/Upnize-Restaurant.svg';
import { useTheme } from '@mui/system';
import { useState } from 'react';
import H5Style from '../../components/textComponents/H5Style';
import PStyle from '../../components/textComponents/PStyle';
import H1Style from '../../components/textComponents/H1Style';
import H4Style from './../../components/textComponents/H4Style';
import { getLocations } from '../../services';
import { useNavigate } from 'react-router-dom';


const RightTextComp = ({ children, matches }) => {
  return (
    <div
      className={`${classes.textContainer} ${
        !matches ? classes.rightTextSection : classes.responsiveRightTextSection
      }`}
    >
      {children}
    </div>
  );
};

const LeftTextComp = ({ children, matches }) => {
  return (
    <div
      className={`${classes.textContainer} ${
        !matches ? classes.leftTextSection : classes.responsiveLeftTextSection
      }`}
    >
      {children}
    </div>
  );
};

const LeftImgComp = ({ src }) => {
  return (
    <div className={`${classes.imgContainer} ${classes.leftImgSection}`}>
      <img className={classes.productImg} src={src} />
    </div>
  );
};

const RightImgComp = ({ src }) => {
  return (
    <div className={`${classes.imgContainer} ${classes.rightImgSection}`}>
      <img className={classes.productImg} src={src} />
    </div>
  );
};

const ChipsContainer = ({ children, matches }) => {
  return (
    <div
      className={`${classes.chipsContainer} ${
        matches ? classes.responsiveChipsContiner : ''
      }`}
    >
      {children}
    </div>
  );
};

const StartPage = () => {

  const [keyword, setKeyword] = useState('');
  const [location, setLocation] = useState('');
  const [industry, setIndustry] = useState('');
  const navigate = useNavigate();
  const searchLocations = async () => {
    const result = await getLocations(keyword, location, industry);
    console.log(result.data);
    if (result.data)
      navigate('/network', {
        state: {
          data: result.data,
          keyword: keyword,
          location: location,
          industry: industry,
        },
      });
  };

  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('md'));
  const [matches, setMatches] = useState(
    window.matchMedia('(max-width: 900px)').matches
  );

  useEffect(() => {
    window
      .matchMedia('(max-width: 900px)')
      .addEventListener('change', (e) => setMatches(e.matches));
  }, []);

  return (
    <Grid
      item
      container
      sx={{
        minWidth: { xs: '100%', md: 'auto' },
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {/* first section */}

      <Box
        sx={{
          display: 'flex',
          width: '100%',
          position: 'relative',
          justifyContent: 'center',
          mb: 8,
        }}
      >
        <Box
          sx={{
            display: { xs: 'none', md: 'flex' },
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <img
            src={collage1}
            alt="collage1"
            className={classes.collage__image}
          />
          <img
            src={collage2}
            alt="collage2"
            className={classes.collage__image}
          />
          <img
            src={collage3}
            alt="collage3"
            className={classes.collage__image}
          />
          <img
            src={collage4}
            alt="collage4"
            className={classes.collage__image}
          />
          <img
            src={collage5}
            alt="collage5"
            className={classes.collage__image}
            style={{ flex: 1.1 }}
          />
          <img
            src={collage6}
            alt="collage6"
            className={classes.collage__image}
          />
        </Box>
        <Box
          sx={{
            display: { xs: 'flex', md: 'none' },
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <img
            src={collage5}
            alt="collage5"
            className={classes.collage__image}
            style={{ flex: 1.1 }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'start',
            position: 'absolute',
            zIndex: 50,
            bottom: '50%',
            top: { xs: '60%', md: '43%' },
          }}
        >
          <CustomTypography
            sx={{
              fontWeight: 'bold',
              marginBottom: 28,
              fontSize: { xs: '20vw', md: '10.5vw' },
              color: 'white',
              zIndex: 99,
              marginLeft: '5vw',
              textShadow: '0px 0px 10px rgb(0,0,0,0.2)',
            }}
          >
            Stay like a local
          </CustomTypography>
        </Box>
      </Box>

      <div className="body__container">
        <Grid
          xs={12}
          sx={{

            paddingLeft: {
              xs: '16px !important',
              sm: '40px !important',
              md: '40px !important',
            },
            paddingRight: {
              xs: '16px !important',
              sm: '40px !important',
              md: '40px !important',
            },

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <Grid xs={12}>
            <Box
              sx={{
                // margin: 2,
                mt: 0,
                boxShadow:
                  '0px 2px 4px -1px rgb(0 0 0 / 5%), 0px 4px 5px 2px rgb(0 0 0 / 5%), 0px 1px 10px 5px rgb(0 0 0 / 5%)',
                borderRadius: 2,
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
              }}
            >
              <CustomTextField
                sx={{
                  border: 'none',
                  mt: { xs: 1, md: 0 },
                  height: { xs: 60, md: 90 },
                  '& fieldset': {
                    border: 'none',
                    height: { xs: 60, md: 90 },
                  },
                }}
                onChange={(e) => setKeyword(e.target.value)}
                placeholder={'What are you searching for?'}
                inputProps={{
                  style: {
                    height: isSmallScreen ? 60 : 90,
                    padding: 2,
                    paddingLeft: 40,
                    paddingRight: 40,
                    fontSize: '20px',
                  },
                }}
              ></CustomTextField>
              <Divider
                sx={{
                  borderLeft: '#D9DAE2 1px solid',
                  my: { xs: 0, md: 2.5 },
                  mx: { xs: 3, md: 0 },
                }}
              />
              <CustomTextField
                sx={{
                  border: 'none',
                  height: { xs: 60, md: 90 },

                  '& fieldset': {
                    border: 'none',
                    height: { xs: 60, md: 90 },
                  },
                }}
                onChange={(e) => setLocation(e.target.value)}
                placeholder={'Location'}
                inputProps={{
                  style: {
                    height: isSmallScreen ? 60 : 90,
                    padding: 2,
                    paddingLeft: 40,
                    paddingRight: 40,
                    fontSize: '20px',
                  },
                }}
              ></CustomTextField>
              <Divider
                sx={{
                  borderLeft: '#D9DAE2 1px solid',
                  my: { xs: 0, md: 2.5 },
                  mx: { xs: 3, md: 0 },
                }}
              />
              <CustomAutocomplete
                sx={{
                  height: { xs: 60, md: 90 },
                  pr: '24px',
                  paddingLeft: '28px',
                  '& fieldset': {
                    border: 'none',
                    height: { xs: 60, md: 90 },
                  },
                }}
                onSelect={(e) => setIndustry(e.target.value)}
                options={['test', 'test']}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        height: isSmallScreen ? 60 : 90,
                        padding: 0,
                        fontSize: '20px',
                      },
                    }}
                    placeholder="Industry"
                  />
                )}
              ></CustomAutocomplete>
              <Divider
                sx={{
                  display: { xs: 'flex', md: 'none' },
                  borderLeft: '#D9DAE2 1px solid',
                  my: { xs: 0, md: 2.5 },
                  mx: { xs: 3, md: 0 },
                }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CustomButton
                  onClick={searchLocations}
                  sx={{
                    m: 2.5,
                    mt: { xs: 3, md: 2.5 },
                    px: { xs: 0, md: 10 },
                    width: { xs: '50%', md: 'auto' },
                    backgroundColor: '#3A3952',
                    borderRadius: 2,
                    fontSize: '18px',
                    ':hover': { backgroundColor: '#3A3952' },
                  }}
                >
                  search
                </CustomButton>
              </Box>
            </Box>
          </Grid>

          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              // flexDirection: "column",
              flexDirection: { xs: 'column', sm: 'column', md: 'row' },
            }}
          >
            <Grid xs={12} md={4}>
              <Box
                sx={{
                  margin: 2,
                  padding: 5,
                  px: { xs: 0, md: 5 },
                  mx: { xs: 0 },
                  pb: { xs: 0 },
                }}
              >
                <H5Style
                  sx={{
                    textAlign: 'center',
                    // mb: 2,
                  }}
                >
                  Make appointment
                </H5Style>
                <PStyle
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  Easily setup an appointment directly from the business listing
                  page using our integrated booking options.
                </PStyle>
              </Box>
            </Grid>

            <Grid xs={12} md={4}>
              <Box
                sx={{
                  margin: 2,
                  padding: 5,
                  px: { xs: 0, md: 5 },
                  mx: { xs: 0 },
                  pb: { xs: 0 },
                }}
              >
                <H5Style
                  sx={{
                    textAlign: 'center',
                    // mb: 2,
                  }}
                >
                  Find business
                </H5Style>
                <PStyle
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  Discover & connect with great local businesses in your local
                  neighborhood like beauty salon, Photography, home cleaner and
                  more.
                </PStyle>
              </Box>
            </Grid>

            <Grid xs={12} md={4}>
              <Box
                sx={{
                  margin: 2,
                  padding: 5,
                  px: { xs: 0, md: 5 },
                  mx: { xs: 0 },
                  pb: { xs: 0 },
                }}
              >
                <H5Style
                  sx={{
                    textAlign: 'center',
                    // mb: 2,
                  }}
                >
                  Review listing
                </H5Style>
                <PStyle
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  Get valuable insights about listings and tell other readers
                  about your experiences by leaving reviews for businesses.
                </PStyle>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {/* Products */}
      <div className={classes.productsContainer}>
        <H1Style
          className={classes.title}
          sx={{
            mb: '70px',
            mx: 'auto',
          }}
        >
          Products that Upnize Provide
        </H1Style>
        <div className={classes.contentContainer}>
          <LeftTextComp matches={matches}>
            <H4Style>
              <span className={classes.productTitle}>Upnize Service</span>{' '}
              <span>Created for over 30 industries</span>
            </H4Style>
            <PStyle className={classes.productDescription}>
              Upnize's service appointment scheduling system ensures you never
              miss another appointment due to forgetfulness.
              <br />
              Streamline your work processes and make better use of your
              resources by eliminating unnecessary paperwork with Upnize.
              <br /> Upnize fully automates online bookings, from scheduling to
              hosting appointments, to maximize your productivity and
              performance.
              <br /> With Upnize's advanced workflow management, the appointment
              system effortlessly facilitates communication between you and your
              customers.
              <br /> Upnize's service appointment scheduling system eliminates
              the risk of missed appointments and simplifies communication with
              customers through sophisticated workflow management.
              <br /> With Upnize 24/7 availability, your booking system will
              continue to receive bookings even outside of regular working
              hours.
            </PStyle>

            <ChipsContainer matches={matches}>
              <Chip>Customer view</Chip>
              <Chip>Appointments</Chip>
              <Chip>Staff</Chip>
              <Chip>Services</Chip>
              <Chip>Locations</Chip>
            </ChipsContainer>
          </LeftTextComp>
          {!matches && <RightImgComp src={upnizeServiceImg} />}
        </div>

        <div className={classes.contentContainer}>
          {!matches && <LeftImgComp src={upnizeRestaurantImg} />}
          <RightTextComp matches={matches}>
            <H4Style>
              <span>Booking a table with</span>{' '}
              <span className={classes.productTitle}>Upnize Restaurant</span>
            </H4Style>
            <PStyle className={classes.productDescription}>
              Let Upnize Restaurant generate leads for you by easily connecting
              with your existing clients or registered users through a
              streamlined appointment booking process.
              <br /> The best appointment booking system saves customer data,
              including booking history and payment details, to make things
              easier for you.
              <br /> Streamline your appointments by allowing customers to
              self-schedule and choose their own appointment times.
              <br /> Remove the stress of scheduling appointments with Upnize
              Restaurant's self-scheduling feature and 24/7 availability.
              <br /> This will allow your customers to choose the best time for
              them, and your booking system will continue to receive bookings
              beyond regular working hours.
            </PStyle>

            <ChipsContainer>
              <Chip>Lunch menu</Chip>
              <Chip>Ala carte menu</Chip>
              <Chip>Multiple locations</Chip>
              <Chip>Appointments</Chip>
            </ChipsContainer>
          </RightTextComp>
        </div>
      </div>
    </Grid>
  );
};

export default StartPage;
