import { Box, Typography } from "@mui/material";
import React from "react";
import H6Style from "./../textComponents/H6Style";

const Title = ({ title }) => {
  return (
    <div>
      {title ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px 27px 0px 27px",
            marginBottom: "-6px",
          }}
        >
          <H6Style
            variant="h6"
            // color="#4B64E9"
            // fontWeight="bold"
            // fontSize="20px"
          >
            {title}
          </H6Style>
        </Box>
      ) : (
        <div style={{ marginTop: "16px" }} />
      )}
    </div>
  );
};

export default Title;
