import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Rating from '@mui/material/Rating';
import BookingComponent from '../../../components/BookingWidget/BookingDialog/BookingComponent';
import useGetInfo from '../../../components/BookingWidget/hook/useGetInfo';
import H1Style from '../../../components/textComponents/H1Style';
import PStyle from '../../../components/textComponents/PStyle';

const Reviews = ({ data }) => {
  const { step } = useGetInfo();
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    setReviews(data.reviews.rows);
  }, []);

  return (
    <Box>
      <Box className="body__container">
        <Grid>
          <H1Style
            sx={{
              marginBottom: '20px',
            }}
          >
            Reviews
          </H1Style>
        </Grid>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: { md: 'row', xs: 'column' },
          }}
        >
          <Box
            md={9}
            xs={12}
            sx={{
              marginRight: '20px',
              flex: '1 1 auto',
            }}
          >
            {reviews.map((i) => (
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Grid sx={{ marginBottom: '20px' }}>
                  <Box>
                    <Rating
                      max={4}
                      readOnly
                      value={i.rating}
                      sx={{
                        '& .MuiRating-iconFilled': {
                          color: '#D2D219',
                        },
                        '& .MuiRating-iconEmpty': {
                          color: '#D9DFE5',
                        },
                      }}
                    />
                  </Box>
                  <Box sx={{ marginLeft: '5px', width: '100%' }}>
                    <PStyle
                      sx={{
                        marginBottom: '8px',
                        marginTop: '0px',
                      }}
                    >
                      {i.body}
                    </PStyle>
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                      }}
                    >
                      Created: {`${i?.customer?.User?.firstName}`},{` `}
                      {`${new Date(i.createdAt).getFullYear()}-${
                        new Date(i.createdAt).getMonth() + 1
                      }-${new Date(i.createdAt).getDate()}`}
                    </Typography>
                  </Box>
                </Grid>
              </Box>
            ))}
          </Box>
          <Box
            md={3}
            xs={12}
            sx={{
              flex: { md: '0 0 350px' },
            }}
          >
            <BookingComponent step={step} />
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default Reviews;
