import { Box, Typography } from "@mui/material";
import useGetInfo from "./hook/useGetInfo";

const Summary = ({ VAT }) => {
  const { infoList, priceInfo, total } = useGetInfo();
  const CouponData = priceInfo.find((data) => data.coupon);
  const giftCardData = priceInfo.find((data) => data.giftCard);
  const dateData = infoList.find((info) => info.type === "Date");
  const date = dateData.date;
  const year = date.split("-")[2];
  const month = date.split("-")[1];
  const day = date.split("-")[0];
  return (
    <>
      {infoList.map((info) => (
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ borderBottom: "1px solid #ddd" }}
          key={info.id}
        >
          <Box marginY="7px">
            <Typography
              color="gray"
              variant="p"
              sx={{ textTransform: "uppercase" }}
              fontWeight="400"
              fontSize="16px"
            >
              {info.type}
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "16px", color: "#4B64E9" }}
            >
              {info.option
                ? info.option.name || info?.option?.User?.firstName
                : info.date
                ? `${year}-${month}-${day}`
                : info.discount
                ? info.discount
                : info.balance
                ? info.balance
                : info.people}
              {info.discount ? "$" : ""}
            </Typography>
          </Box>
          <Box marginY="7px" textAlign="end">
            <Typography
              color="gray"
              variant="p"
              sx={{ textTransform: "uppercase" }}
              fontWeight="400"
              fontSize="16px"
              textAlign="end"
            >
              {info.price
                ? "Price"
                : info.date
                ? "Time"
                : info.type === "people"
                ? ""
                : ""}
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "16px", color: "#4B64E9" }}
            >
              {info.price ? "$" : ""}
              {info.price
                ? info.price
                : info.date
                ? info.time
                : info.type === "people"
                ? info.quantity
                : ""}
            </Typography>
          </Box>
        </Box>
      ))}
      {CouponData || giftCardData ? (
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ margin: "1rem 0", borderBottom: "1px solid #ddd" }}
        >
          {CouponData ? (
            <Box marginY="7px">
              <Typography
                color="gray"
                variant="p"
                sx={{ textTransform: "uppercase" }}
                fontWeight="400"
                fontSize="16px"
              >
                COUPON
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{ fontSize: "16px", color: "#4B64E9" }}
              >
                ${CouponData?.coupon}
              </Typography>
            </Box>
          ) : (
            ""
          )}
          {giftCardData ? (
            <Box marginY="7px" textAlign={!CouponData ? "" : "end"}>
              <Typography
                color="gray"
                variant="p"
                sx={{ textTransform: "uppercase" }}
                fontWeight="400"
                fontSize="16px"
              >
                Gift Card
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{ fontSize: "16px", color: "#4B64E9" }}
              >
                ${giftCardData.amount}
              </Typography>
            </Box>
          ) : (
            ""
          )}
        </Box>
      ) : (
        ""
      )}

      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ margin: "1rem 0", borderBottom: "1px solid #ddd" }}
      >
        <Box marginY="7px">
          <Typography
            color="gray"
            variant="p"
            sx={{ textTransform: "uppercase" }}
            fontWeight="400"
            fontSize="16px"
          >
            Total Price
          </Typography>
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{ fontSize: "16px", color: "#4B64E9" }}
          >
            ${total}
          </Typography>
        </Box>
        <Box marginY="7px" textAlign="end">
          <Typography
            color="gray"
            variant="p"
            sx={{ textTransform: "uppercase" }}
            fontWeight="400"
            fontSize="16px"
          >
            Vat
          </Typography>
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{ fontSize: "16px", color: "#4B64E9" }}
          >
            ${VAT.toFixed(2)}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Summary;
