import { Box } from "@mui/material";
import React, { useContext, useState } from "react";
// import useGetInfo from "./hook/useGetInfo";
import Header from "./Header";
import Footer from "./Footer";
import styled from "@emotion/styled";
// import ConfirmProvider, { ConfirmContext } from "./context/confirmProvider";
import ConfirmOption from "./ConfirmOption";
import useGetInfo from "./hook/useGetInfo";
import Title from "./Title";

const Container = styled(Box)({
  // maxWidth: "1000px",
  // maxHeight: "700px",
  width: "100%",
  border: "1px solid #E2E5FC",
  position: "relative",
});
const AppLayout = ({ children, about, isDialog }) => {
  const { step } = useGetInfo();

  // const [confirmState, setConfirmState] = useState(false);
  // console.log(confirmState);

  const { isConfirmStep } = useGetInfo();
  return (
    // <ConfirmProvider value={{ confirmState, setConfirmState }}>
    <Box
      sx={{
        width: about
          ? { sm: "350px", xs: "100%" }
          : isDialog
          ? { width: "100%" }
          : { md: "350px", sm: "87vw" },

        // width: "100%",
        // height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container>
        {isConfirmStep ? (
          <ConfirmOption />
        ) : (
          <>
            <Header />
            <Title title={step.title} />

            {children}
            <Footer />
          </>
        )}
      </Container>
    </Box>
    // </ConfirmProvider>
  );
};

export default AppLayout;
