import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CustomTypography from "../../components/CustomTypography";
import CustomAutocomplete from "../../components/CustomAutocomplete";
import {
  Button,
  Dialog,
  DialogContent,
  InputAdornment,
  Slide,
  TextField,
} from "@mui/material";
import resturant from "../../assets/images/subscribe/Restaurant-dinner-booking.jpg";
import barber from "../../assets/images/marketplace/barber.png";
import classes from "./Newslist.module.css";
import CustomTextField from "../../components/CustomTextField";
import SearchIcon from "@mui/icons-material/Search";
import CustomButton from "../../components/CustomButton";
import Chip from "../../components/Chip";
import {
  getCategoriesAPI,
  getNewsListAPI,
  getNewsListFilterAPI,
} from "../../services";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { getImage } from "../../utils/getImage";
import moment from "moment";
import { useNavigate } from "react-router";
import { getPostDate } from "../../utils/getPostDate";
import { goUpThePage } from "../../utils/goUpThePage";
import H1Style from "../../components/textComponents/H1Style";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";
import { BACKEND_URL } from "../../constants/endpoint";

const duration = [
  {
    duration: "0 Seats",
  },
  {
    duration: "15 Seats",
  },
  {
    duration: "30 Seats",
  },
  {
    duration: "45 Seats",
  },
  {
    duration: "1 Seats",
  },
];

const dummyNewsList = [
  {
    title:
      "Company name here Company name here Company name hereCompany name here",
    date: "10 October 2022",
    perks: [
      { perk: "Up to 2 team member", description: "perks have descriptions" },
      {
        perk: "Up to 100 items",
        description: "perks have descriptionssssssssss",
      },
    ],
    categories: ["Category", "Second"],
    image: barber,
    reviews: 4,
    reviewsCount: 7,
  },
  {
    title: "Company name here",
    date: "10 October 2022",
    perks: [
      { perk: "Up to 2 team member", description: "perks have descriptions" },
      {
        perk: "Up to 100 items",
        description: "perks have descriptionssssssssss",
      },
    ],
    categories: ["Category", "Second"],
    image: resturant,
  },
  {
    title: "Company name here",
    date: "10 October 2022",
    perks: [
      { perk: "Up to 2 team member", description: "perks have descriptions" },
      {
        perk: "Up to 100 items",
        description: "perks have descriptionssssssssss",
      },
    ],
    categories: ["Category", "Second"],
    image: barber,
    reviewsCount: 99,
    reviews: 5,
  },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NewsList = () => {
  const navigate = useNavigate();
  const [keywordFilter, setKeywordFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [categories, setCategories] = useState([]);
  const [sortedBy, setSortedBy] = useState("");
  const [newsList, setNewsList] = useState([]);

  const getNewsListFromAPI = useCallback(async () => {
    try {
      const newsListResponse = await getNewsListAPI();
      const categoriesResponse = await getCategoriesAPI();
      setNewsList(newsListResponse.data);
      setCategories(categoriesResponse.data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getNewsListFromAPI();
  }, [getNewsListFromAPI]);

  const handleKeywordFilter = (e) => {
    setKeywordFilter((prevState) => e.target.value);
  };

  const handleCategoryFilter = (e, newValue) => {
    setCategoryFilter((prevState) => newValue);
  };

  const handleSortedBy = (e, newValue) => {
    setSortedBy((prevState) => newValue);
  };

  // needs to be updated
  const handleGetNewsListByFilter = async () => {
    try {
      const category = categories.find(
        (category) => category.label === categoryFilter
      );
      const categoryId = category ? category.id : 0;

      const response =
        !categoryId && !keywordFilter
          ? await getNewsListAPI()
          : await getNewsListFilterAPI({ categoryId, keyword: keywordFilter });
      setNewsList(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const navigateToNews = (newsId) => () => {
    navigate(`/news/${newsId}`);
  };

  const handleNavigation = (link) => () => {
    navigate(link);
    goUpThePage();
  };

  const FilterComp = ({ isDialog = true }) => {
    const [categoryFilter, setCategoryFilter] = useState("");
    const [categories, setCategories] = useState([]);
    const [value, setValue] = React.useState(0);
    const [keywordFilter, setKeywordFilter] = useState("");
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const handleCategoryFilter = (e, newValue) => {
      setCategoryFilter((prevState) => newValue);
    };
    const handleKeywordFilter = (e) => {
      setKeywordFilter((prevState) => e.target.value);
    };
    return (
      <Box>
        <Box sx={{ mt: 0, mb: { xs: 4, xl: 0 }, width: "350px" }}>
          <CustomTextField
            value={keywordFilter}
            onChange={handleKeywordFilter}
            customData={{
              label: "Keyword",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon sx={{ color: "#D9DAE2" }} />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 3 }}
          ></CustomTextField>
          <CustomAutocomplete
            value={categoryFilter}
            onChange={handleCategoryFilter}
            options={categories.map((category) => category.label)}
            customData={{
              label: "Category",
              // labelSx: { fontSize: "14px" },
            }}
            renderInput={(params) => <TextField {...params} />}
            sx={{ mb: 3 }}
          />
          {/* <CustomButton onClick={handleGetNewsListByFilter} sx={{ px: 7 }}>
            Filter
          </CustomButton> */}
        </Box>

        <Box
          onClick={handleNavigation("/subscribe")}
          className={classes.subToNewsLetter}
          sx={{ width: "350px" }}
        >
          Subscribe to Newsletter
        </Box>
      </Box>
    );
  };
  const appBarHeight = "95px";
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid
      item
      container
      sx={{
        minWidth: { xs: "100%", md: "auto" },
        justifyContent: "center",
        paddingLeft: {
          xs: "16px !important",
          sm: "40px !important",
          md: "40px !important",
        },
        paddingRight: {
          xs: "16px !important",
          sm: "40px !important",
          md: "40px !important",
        },
      }}
    >
      <Dialog
        fullScreen
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          zIndex: "998 !important",
          top: `${appBarHeight} !important`,
        }}
      >
        <DialogContent>
          <FilterComp isDialog={true} />
        </DialogContent>
      </Dialog>
      <Button
        onClick={() => setOpen(!open)}
        variant="contained"
        sx={{
          width: "150px",
          position: "fixed",
          bottom: "20px",
          left: "0",
          right: "0",
          mx: "auto",
          display: { xs: "flex", sm: "flex", md: "none" },
          gap: "5px",
          zIndex: "999 !important",
        }}
      >
        {open ? <CloseIcon /> : <FilterAltIcon />}
        FILTER
      </Button>
      <div className="body__container">
        <Grid>
          <Box sx={{ display: "flex", width: "100%" }} mb={4}>
            <H1Style
              sx={{
                // fontWeight: "bold",
                marginTop: 12,
                marginBottom: 2,
                // fontSize: 50,
                // color: "#242424",
              }}
            >
              Press release for Upnize
            </H1Style>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: { xs: "column-reverse", md: "row" },
            }}
          >
            <Box
              xs={12}
              md={9}
              sx={{
                flex: "1 1 auto",
              }}
            >
              <Box sx={{ mr: { xs: 0, md: 2 } }}>
                <Grid
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    mr: { xs: 0, md: 2 },
                    mb: 1,
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <CustomTypography
                      sx={{
                        fontSize: 18,
                        color: "#1976D2",
                        fontWeight: "700",
                      }}
                    >
                      {newsList.length}
                    </CustomTypography>
                    <CustomTypography
                      sx={{
                        fontSize: 18,
                        color: "#1976D2",
                        ml: 1,
                      }}
                    >
                      result found
                    </CustomTypography>
                  </Box>
                  {/**
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: { xs: "100%", md: "300px" },
                      mt: { xs: 4, md: 0 },
                    }}
                  >
                    <CustomTypography
                      sx={{
                        fontSize: 14,
                        color: "#1976D2",
                        width: "50%",
                      }}
                    >
                      SORTED BY
                    </CustomTypography>
                    <CustomAutocomplete
                    onChange={handleSortedBy}
                      options={duration.map((option) => option.duration)}
                      defaultValue={"Most popular"}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              style: {
                                fontWeight: "700",
                                color: "#3A3A50",
                              },
                            }}
                            placeholder="Select..."
                          />
                        );
                      }}
                    />
                  </Box>
                  */}
                </Grid>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  {newsList.map((resultInfo, i) => {
                    const {
                      createdAt: date,
                      title,
                      categoryId,
                      image,
                    } = resultInfo;

                    return (
                      <>
                        <Grid
                          onClick={navigateToNews(resultInfo.id)}
                          xs={12}
                          sx={{
                            cursor: "pointer",
                            display: "flex",
                            width: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              p: { xs: "25px 0px", md: "10px 16px 0 0" },
                              mb: 4,
                              borderRadius: "6px",
                              width: "100%",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                backgroundColor: "#F7F8FB",
                              }}
                              mb={2}
                            >
                              <Box
                                sx={{
                                  height: "250px",
                                  width: "fit-content",
                                }}
                              >
                                <img
                                  src={getImage(
                                    image ? `${BACKEND_URL + image}` : null
                                  )}
                                  alt=""
                                  className={classes.blog__image}
                                />
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <CustomTypography
                                fontWeight={900}
                                fontSize={"30px"}
                                sx={{
                                  textOverflow: "ellipsis",
                                  width: "100%",
                                  whiteSpace: { xs: "wrap", md: "nowrap" },
                                  overflow: "hidden",
                                }}
                              >
                                {title}
                              </CustomTypography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <CustomTypography
                                fontSize={"18px"}
                                mt={1}
                                mb={2}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                {getPostDate(date)}
                              </CustomTypography>
                            </Box>

                            <Box sx={{ display: "flex", mb: 2 }}>
                              <Chip>
                                {
                                  categories.find(
                                    (category) => category.id === categoryId
                                  )?.label
                                }
                              </Chip>
                            </Box>
                          </Box>
                        </Grid>
                      </>
                    );
                  })}
                </Box>
              </Box>
            </Box>
            <Box
              xs={12}
              md={3}
              sx={{
                flex: "0 0 350px",
                display: { xs: "none", sm: "none", md: "block" },
              }}
            >
              <FilterComp />
              {/* <Box
                onClick={handleNavigation("/subscribe")}
                className={classes.subToNewsLetter}
                sx={{ width: "350px" }}
              >
                Subscribe to Newsletter
              </Box> */}
            </Box>
          </Box>
        </Grid>
      </div>
    </Grid>
  );
};

export default NewsList;
