import styled from "@emotion/styled";
import { Box } from "@mui/material";
import React, { useEffect } from "react";
import useGetInfo from "./hook/useGetInfo";
import Auth from "./AuthOption";
import DateTimeOption from "./DateTimeOption";
import ExtraService from "./ExtraService";
import Information from "./Information";
import LocationOption from "./LocationOption";
import PeopleOption from "./PeopleOption";
import ServiceOption from "./ServiceOption";
import StaffOption from "./StaffOption";
import PaymentOption from "./PaymentOption";
import ConfirmOption from "./ConfirmOption";

const Container = styled(Box)({
  height: "370px",
  display: "flex",
  flexDirection: "column",
  overflow: { md: "hidden" },
  overflowY: "scroll",
  padding: "1rem",
  "&::-webkit-scrollbar": {
    width: "7px",
  },
  "&::-webkit-scrollbar-track": {
    "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,.1)",
    // outline: '1px solid slategrey'
  },
});
const Options = ({ options, stepId, type, isDialog }) => {
  const { infoList, setInfoList } = useGetInfo();

  const handelClick = (option, id, type, subId, price = 0) => {
    const findInfo = infoList.find((info) => info.id === id);
    if (type === "service") {
      if (!findInfo) {
        setInfoList([...infoList, { id, subId, option, type, price: price }]);
      } else {
        const userInfo = infoList.map((inf) => {
          if (inf.id === id) {
            inf = { id: inf.id, subId, option, type, price: price };
          }
          return inf;
        });
        setInfoList(userInfo);
      }
    } else if (type === "Extra Service") {
      const userAns = infoList.find((inf) => inf.option === option);
      if (userAns?.id) {
        const userInfo = infoList.filter((op) => op.option !== option);
        setInfoList(userInfo);
        return;
      }
      if (!findInfo) {
        setInfoList([...infoList, { id, option, type, price: price }]);
      } else {
        const userInfo = infoList.map((inf) => {
          if (inf.id === id) {
            if (inf.option === option) {
              // const answer = infoList.filter((op) => op.option !== option);
              // return answer;
            } else {
              inf = { id: inf.id, option, type, price: price };
            }
          }
          return inf;
        });
        setInfoList([...userInfo]);
      }
    } else {
      if (!findInfo) {
        setInfoList([...infoList, { id, option, type }]);
      } else {
        const userInfo = infoList.map((inf) => {
          if (inf.id === id) {
            inf = { id: inf.id, option, type };
          }
          return inf;
        });
        setInfoList([...userInfo]);
      }
    }
  };

  const isChecked = (option, id, subId) => {
    const findInfo = infoList.find((info) => info.id === id);
    if (!findInfo) return false;
    if (subId) return findInfo.option === option && findInfo.subId === subId;
    return findInfo.option.id === option.id;
  };

  return (
    <Container
      style={
        type === "allInfo"
          ? { height: "27rem" }
          : // : type === "auth"
          // ? { overflow: "scroll" }
          (isDialog && type === "datetime") || (isDialog && type === "auth")
          ? { height: "100%" }
          : {}
      }
    >
      {type === "location" && (
        <LocationOption
          options={options}
          stepId={stepId}
          handelClick={handelClick}
          isChecked={isChecked}
        />
      )}
      {type === "staff" && (
        <StaffOption
          options={options}
          stepId={stepId}
          handelClick={handelClick}
          isChecked={isChecked}
        />
      )}
      {type === "service" && (
        <ServiceOption
          options={options}
          stepId={stepId}
          handelClick={handelClick}
          isChecked={isChecked}
          infoList={infoList}
        />
      )}
      {type === "extraService" && (
        <ExtraService
          options={options}
          stepId={stepId}
          handelClick={handelClick}
          isChecked={isChecked}
        />
      )}
      {type === "people" && <PeopleOption options={options} stepId={stepId} />}
      {type === "datetime" && (
        <DateTimeOption options={options} stepId={stepId} isDialog={isDialog} />
      )}
      {type === "auth" && <Auth stepId={stepId} />}
      {type === "allInfo" && <Information stepId={stepId} />}
      {type === "payment" && <PaymentOption stepId={stepId} />}
      {/* {type === "dailytime" && <DailyTime stepId={stepId} />} */}
    </Container>
  );
};

export default Options;
