import { Divider } from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import H4Style from "../../textComponents/H4Style";
import classes from "./HelpCenterSideSection.module.css";

const HelpCenterSideSection = () => {
  const navigate = useNavigate();
  const [latestHelpTickets, setLatestHelpTickets] = useState([]);

  const handleNavigation = (page) => {
    return () => {
      navigate(page);
    };
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.latestHelpTicketsContainer}>
        <H4Style>Latest help tickets</H4Style>
        {latestHelpTickets.map((latestHelpTicket, index) => (
          <div
            onClick={handleNavigation(latestHelpTicket.link)}
            className={classes.itemContainer}
          >
            {latestHelpTicket.title}
          </div>
        ))}
        {latestHelpTickets.length === 0 && <>No Latests Tickets Found</>}
      </div>
      <div>
        <H4Style>Suggestions</H4Style>
        <div
          onClick={handleNavigation("/contact-support")}
          className={classes.itemContainer}
        >
          Suggest a feature
        </div>
      </div>
    </div>
  );
};

export default HelpCenterSideSection;
