import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import classes from "./Contact.module.css";
import CustomTypography from "../../components/CustomTypography";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import H1Style from "../../components/textComponents/H1Style";

const Contact = () => {
  const navigation = useNavigate();

  const pages = [
    { label: "Contact support", link: "/contact-support" },
    { label: "Chat with support", link: "/chat-support" },
    { label: "Visit our Help Center", link: "/help-center" },
  ];

  const handleNavigation = (link) => () => {
    navigation(link);
  };

  return (
    <Grid
      item
      container
      sx={{
        minWidth: { xs: "100%", md: "auto" },
        justifyContent: "center",
        px: {
          xs: "16px !important",
          sm: "40px !important",
          md: "40px !important",
        },
      }}
    >
      {/* first section */}
      <div className="body__container">
        <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
          <H1Style
            sx={{
              marginTop: 12,
              marginBottom: 15,
            }}
            textAlign={"center"}
          >
            We're here for you
          </H1Style>
        </Box>

        <Grid
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: { xs: "column", md: "row" },
            gap: 2,
          }}
          mb={4}
        >
          {pages.map((page, index) => (
            <Grid
              key={index}
              xs={12}
              md={4}
              className={classes.pageContainer}
              onClick={handleNavigation(page.link)}
              sx={{ width: "100%" }}
            >
              <Box
                sx={{
                  padding: 5,
                  boxShadow:
                    "0px 2px 4px -1px rgb(0 0 0 / 5%), 0px 4px 5px 2px rgb(0 0 0 / 5%), 0px 1px 10px 5px rgb(0 0 0 / 5%)",
                  minHeight: "310px",
                }}
              >
                <CustomTypography
                  sx={{
                    fontSize: 30,
                    mb: 16,
                  }}
                >
                  {page.label}
                </CustomTypography>
                <CustomTypography
                  sx={{
                    cursor: "pointer",
                    fontSize: 16,
                    color: "#1976D2",
                    display: "flex",
                    alignItems: "center",
                    borderBottom: "1px dashed #1976D2",
                    width: "fit-content",
                  }}
                >
                  Head there
                  <ArrowForwardIcon sx={{ ml: 1 }} />
                </CustomTypography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </div>
    </Grid>
  );
};

export default Contact;
