import classes from "./HelpCenterSearch.module.css";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

const HelpCenterSearch = ({handleClearSearch, ...props}) => {
  
    return(
        <div
        style={{
          marginTop: "15px",
          borderBottom: "1px solid #e3eaf3",
        }}
      >
        <div
        className={classes.searchContainer}
        >
          <div
          className={classes.searchIconContainer}
          >
            <SearchIcon className={classes.searchIcon} />
          </div>
          <input
            className={`${classes.searchField} quickSearch`}
            {...props}
          />
                    <div
          className={classes.searchIconContainer}
          >
            <CloseIcon onClick={handleClearSearch} className={classes.closeIcon} />
          </div>
        </div>
      </div>
    )
}

export default HelpCenterSearch;