import classes from "./Features.module.css";
import { featuresList } from "../../constants/features";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import H1Style from "../../components/textComponents/H1Style";
import H2Style from "../../components/textComponents/H2Style";
import H5Style from "./../../components/textComponents/H5Style";
import PStyle from "./../../components/textComponents/PStyle";

const Features = () => {
  const [open, setOpen] = useState(false);
  const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(null);
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 600px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(max-width: 600px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const handleClickOpen = (index) => () => {
    setSelectedFeatureIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const selectedFeature =
    selectedFeatureIndex !== null ? featuresList[selectedFeatureIndex] : null;

  return (
    <div className={classes.mainContainer}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className={classes.dialogTitle}>
          <div className={classes.iconDialogContainer}>
            {selectedFeature?.icon(classes.iconDialog)}
          </div>
          {selectedFeature?.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialogContent}>
            {selectedFeature?.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <div className={classes.headerSection}>
        <H1Style>Features</H1Style>
        <H2Style>
          We connect intuitive design with the latest technology to provide a
          seamless booking experience for you.
        </H2Style>
      </div>

      <div className={classes.featuresContainer}>
        {featuresList.map((feature, index) => (
          <div key={`feature-${index}`} className={classes.featureContainer}>
            <div className={classes.iconSection}>
              <div className={classes.iconContainer}>
                {feature.icon(classes.icon)}
              </div>
            </div>
            <div className={classes.infoSection}>
              <H5Style className={classes.title}>{feature.title}</H5Style>
              <PStyle className={classes.description}>
                {matches ? (
                  <>{feature.description}</>
                ) : (
                  <>
                    {feature.description.substring(0, 120)}
                    {feature.description.length > 120 && (
                      <>
                        ...{" "}
                        <span onClick={handleClickOpen(index)}>Read more</span>
                      </>
                    )}
                  </>
                )}
              </PStyle>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;
