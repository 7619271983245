import { Box } from "@mui/material";
import React, { useRef } from "react";
import useGetInfo from "./hook/useGetInfo";
// import useGetInfo from "./hook/useGetInfo";

const Header = () => {
  const { currentStep, allStep, scrollRef } = useGetInfo();
  return (
    <Box
      ref={scrollRef}
      sx={{
        // padding: "0 23px",
        padding: { sm: "0 23px", xs: "0" },
        background: "#E2E5FC",
        height: { xs: "52px", sm: "75px", md: "80px" },
        display: "flex",
        border: "1px solid #E2E5F",
        overflow: "hidden",
        // overflow: "scroll",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        scrollbarWidth: "none",
        msOverflowStyle: "none",

        // flexWarp:'noWarp'
      }}
    >
      {allStep?.map((step, index) => {
        console.log(currentStep);
        return (
          <img
            src={currentStep === index ? step?.stepDeActive : step?.stepActive}
            key={index}
            alt={step?.title}
            height="100%"
            style={index !== 0 ? { marginLeft: "-22px" } : {}}
          />
        );
      })}
    </Box>
  );
};

export default Header;
