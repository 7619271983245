import { Box, Typography, Button } from '@mui/material';
import React, { useContext, useReducer, useState } from 'react';
import logo from '../../assets/img/confirmation/confirm.png';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import styled from '@emotion/styled';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Tooltip from '@mui/material/Tooltip';
import { ConfirmContext } from './context/confirmProvider';
import useGetInfo from './hook/useGetInfo';
import CircularProgress from '@mui/material/CircularProgress';

const ButtonContainer = styled(Button)({
  '&:hover': {
    color: '#2E2E2E',
    background: '#E8EAEF',
  },
  '&:disabled': {
    backgroundColor: '#E8EAEF',
  },
  background: '#E8EAEF',
  width: '309px',
  color: '#2E2E2E',
  height: '45px',
  margin: '1rem 0',
  border: '2px solid #D9D9D9',
  padding: '5px',
});

const ConfirmContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  height: '100%',
  overflow: 'hidden',
});

const ConfirmOption = () => {
  const { handleResetInfo } = useGetInfo();
  const [isLoading, setIsLoading] = useState(false);

  // const { confirmState, setConfirmState } = useContext(ConfirmContext);
  const handelNewBooking = () => {
    // navigate("/confirm");
    handleResetInfo();
  };

  const [val, setVal] = useState('1 2 3 4');
  const [isCopied, setIsCopied] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, '500');
  };
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
      }}
    >
      <Box
        sx={{
          maxWidth: '100vw',
          maxHeight: '700px',
          width: '100%',
          height: '100%',
          border: '1px solid #E2E5FC',
          padding: '10px',
        }}
      >
        {isLoading ? (
          <Box
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <ConfirmContainer>
            <img src={logo} alt="confirmIcon" height="83px" width="83px" />
            <Box sx={{ marginTop: '50px' }}>
              <Typography
                variant="h2"
                color="#4B64E9"
                sx={{ fontSize: { xs: '35px', sm: '40px', md: '48px' } }}
              >
                THANK YOU FOR YOUR REQUEST
              </Typography>
              <Typography variant="p" fontSize="33px" color="#3E3E3E">
                Your confirmation number :
              </Typography>
            </Box>
            <br />
            <Typography variant="p" fontSize="33px" color="#3E3E3E">
              <CopyToClipboard
                text={val}
                onCopy={() => {
                  setIsCopied(true);
                  handleTooltipOpen();
                  console.log('is Coppied = ', isCopied);
                }}
              >
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  placement="right"
                  onClose={handleTooltipClose}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="Copied"
                >
                  <span style={{ cursor: 'pointer' }}>{val}</span>
                </Tooltip>
              </CopyToClipboard>
              {/* {isCopied && <span>Copied!</span>} */}
            </Typography>

            <Box
              sx={{
                marginX: {
                  xs: '10px',
                  sm: '10px',
                },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: { xs: '95%', sm: '90%', md: '65%' },
              }}
            >
              <Box
                sx={{
                  marginTop: '20px',
                  width: { sm: '100%', xs: '100%', md: '100%' },
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: { sm: 'row', xs: 'column', md: 'row' },
                }}
              >
                <ButtonContainer
                  sx={{
                    width: { xs: '100%', sm: '100%', md: '309px' },
                    marginX: { sm: '10px' },
                  }}
                >
                  <CalendarMonthIcon sx={{ marginRight: '16px' }} />
                  ADD TO GOOGLE CALENDAR
                </ButtonContainer>
                <ButtonContainer
                  sx={{
                    width: { xs: '100%', sm: '100%', md: '309px' },
                    marginX: { sm: '10px' },
                  }}
                  onClick={handelNewBooking}
                >
                  <AddCircleOutlineIcon sx={{ marginRight: '16px' }} />
                  START A NEW BOOKING
                </ButtonContainer>
              </Box>
              <Box sx={{ width: { sm: '70%', xs: '100%' } }}>
                <ButtonContainer
                  style={{ background: '#F7FAFD' }}
                  sx={{ width: { xs: '100%', sm: '100%', md: '206px' } }}
                >
                  <AssignmentTurnedInIcon sx={{ marginRight: '16px' }} />
                  FINISH BOOKING
                </ButtonContainer>
              </Box>
            </Box>
          </ConfirmContainer>
        )}
      </Box>
    </Box>
  );
};

export default ConfirmOption;
