import { Box, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import useGetInfo from './hook/useGetInfo';
import CircularProgress from '@mui/material/CircularProgress';

const PeopleOption = ({ options, stepId }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { infoList, setInfoList } = useGetInfo();
  const info = infoList.find((info) => info.id === stepId);
  const handleChange = (event) => {
    if (info) {
      const userInfo = infoList.map((inf) => {
        if (inf.id === stepId) {
          inf = { ...info, people: event.target.value, type: 'people' };
        }
        return inf;
      });
      setInfoList(userInfo);
      // console.log(data);
    } else {
      const data = { id: stepId, people: event.target.value, type: 'people' };
      setInfoList([...infoList, data]);
    }
  };

  const handelClick = (num) => {
    if (info) {
      const userInfo = infoList.map((inf) => {
        if (inf.id === stepId) {
          inf = { ...info, people: num, type: 'people' };
        }
        return inf;
      });
      setInfoList(userInfo);
    } else {
      const data = { id: stepId, people: num, type: 'people' };
      setInfoList([...infoList, data]);
    }
  };

  return (
    <>
      {isLoading ? (
        <Box
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            {options.map((option) => (
              <Box
                variant="contained"
                sx={{
                  background: `${info?.people === option ? '#4B64E9' : '#ddd'}`,
                  margin: '5px',
                  width: '40px',
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '5px',
                  fontWeight: 500,
                  flexWrap: 'wrap',
                  color: `${info?.people === option ? 'white' : 'black'}`,
                  cursor: 'pointer',
                }}
                key={option}
                onClick={() => handelClick(option)}
              >
                {option}
              </Box>
            ))}
          </Box>
        </div>
      )}
    </>
  );
};

export default PeopleOption;
