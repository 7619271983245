import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { BACKEND_URL } from '../../../constants/endpoint';
import React from 'react';
import H1Style from '../../../components/textComponents/H1Style';

const Gallery = ({ data }) => {
  return (
    <Box>
      <Box className="body__container">
        <Grid
          sx={{
            maxWidth: 'calc(100vw - 48px)',
            marginBottom: '100px',
          }}
        >
          <Grid
            sx={{
              marginBottom: '30px',
            }}
          >
            <H1Style>Exterior</H1Style>
          </Grid>
          <Grid
            sx={{
              display: 'flex',
              overflow: 'scroll',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
            }}
          >
            {data.exteriorImages.map((img) => (
              <Box
                sx={{
                  marginRight: '15px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  style={{
                    maxWidth: '250px',
                  }}
                  src={`${BACKEND_URL}//${img}`}
                  alt=""
                />
              </Box>
            ))}
          </Grid>
        </Grid>

        <Grid
          sx={{
            maxWidth: 'calc(100vw - 48px)',
          }}
        >
          <Grid
            sx={{
              marginBottom: '30px',
            }}
          >
            <H1Style>Interior</H1Style>
          </Grid>
          <Grid
            md={12}
            sx={{
              display: 'flex',
              overflow: 'scroll',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
            }}
          >
            {data.interiorImages.map((img) => (
              <Box
                sx={{
                  marginRight: '15px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  style={{
                    maxWidth: '250px',
                  }}
                  src={`${BACKEND_URL}/${img}`}
                  alt=""
                />
              </Box>
            ))}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Gallery;
