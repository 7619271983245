import { Drawer, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import DocumentationContent from "../../components/Documentation/Content";
import DocumentationSidebar from "../../components/Documentation/Sidebar";
import DocumentationSidebarMobile from "../../components/Documentation/SidebarMobile";
import classes from "./documentation.module.css";

const Documentation = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1000px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(max-width: 1000px)")
      .addEventListener("change", (e) => setMatches(e.matches));
      window
      .matchMedia("(min-width: 1000px)")
      .addEventListener("change", (e) => setOpenDrawer(false));
  }, []);

  const toggleDrawer = (value) => () => {
    setOpenDrawer(value);
  };

  return (
    <div>
        <Drawer
          variant="temporary"
          anchor="right"
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            "& .MuiDrawer-paper": {
              width: "75%",
            },
          }}
          open={openDrawer}
          onClose={toggleDrawer(false)}>
            <div className={classes.drawerContainer}>
              <DocumentationSidebarMobile toggleDrawer={toggleDrawer} />
          </div>
        </Drawer>
      <div className={classes.mainContainer}>
        
        {
            !matches &&   <DocumentationSidebar />
        }
        
        <DocumentationContent matches={matches} toggleDrawer={toggleDrawer} />
      </div>
    </div>
  );
};

export default Documentation;
