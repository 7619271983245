import React, { useEffect, useRef, useState } from 'react';
import { getBooking } from '../../../services';
import StepData from '../StepData';

export const InfoContext = React.createContext(null);

const InfoProvider = ({ children }) => {
  const scrollRef = useRef();
  const scrollRight = (scrollOffset) => {
    scrollRef.current.scrollLeft += scrollOffset;
  };
  const scrollLeft = (scrollOffset) => {
    scrollRef.current.scrollLeft -= scrollOffset;
  };

  const [isConfirmStep, setIsConfirmStep] = useState(false);

  const [priceInfo, setPriceInfo] = useState([{ price: 0 }]);
  const [allStep, setAllStep] = useState(StepData());
  const [infoList, setInfoList] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [total, setTotal] = useState(0);
  const step = allStep[currentStep];
  const [tenantId, setTenantId] = useState('');

  const [isLoading, setIsLoading] = useState(true);
  const [isTimeLoading, setIsTimeLoading] = useState(true);

  useEffect(() => {
    priceInfo.forEach((element) => {
      setTotal(element.price);
      if (element.coupon) {
        setTotal(total - element.coupon);
      } else if (element.amount) {
        // console.log(element.GiftCard);
        setTotal(total - element.amount);
      } else {
        setTotal(element.price);
      }
    });
  }, [priceInfo]);

  useEffect(() => {
    console.log('infoList', infoList);
  }, [infoList]);
  let newSteps = [...allStep];

  const fetchData = async (queries, tenantId) => {
    // const res = await getCompany(queries);
    getBooking(queries, tenantId).then((res) => {
      console.log('TenantId***', tenantId);
      // const subServiceRes = await getCompany(
      //   "serviceId=7&staffId=7&locationId=5"
      // );
      // let newSteps = [...allStep];
      newSteps[0].options = res.data.Locations || newSteps[0].options;
      newSteps[1].options = res.data.Staff || newSteps[1].options;
      newSteps[2].options = res.data.Services || newSteps[2].options;
      newSteps[3].options = res.data.SubServices || newSteps[3].options;
      newSteps[4].options = res.data || [];
      setAllStep(newSteps);
      // setIsLoading(false);
      // console.log("getCompany||||||||||getCompany", res.data);
    });
    setIsTimeLoading(false);
  };

  const getQueries = () => {
    let queryText = infoList
      .map((info) => {
        if (
          info.id <= 4 &&
          currentStep + 1 !== info.id &&
          info.id < currentStep + 1
        ) {
          return info.id === 4
            ? `subServiceId=${info.option.id}`
            : `${info.type.toLowerCase()}Id=${info.option.id}`;
        }
      })
      .join('&');
    return queryText;
  };

  // check if extra not found
  useEffect(() => {
    if (allStep[3].options.length === 0 && currentStep === 3 && !isLoading) {
      setCurrentStep(4);
      setInfoList([
        ...infoList,
        {
          id: 4,
          option: {
            id: -1,
          },
          type: 'Extra Service',
          price: 0,
        },
      ]);
    }
  }, [currentStep, isLoading]);

  useEffect(() => {
    if (tenantId) {
      const queries = getQueries();
      fetchData(queries, tenantId);
      setIsLoading(true);
      console.log('queries====', queries);
    }
  }, [currentStep, tenantId]);

  useEffect(() => {
    setIsLoading(false);
  }, [allStep]);

  const handelNext = () => {
    if (allStep.length === currentStep) {
      return;
    }

    setCurrentStep((next) => next + 1);
    setIsLoading(true);
  };

  const handelBack = () => {
    if (0 === currentStep) {
      return;
    }
    if (allStep[3].options.length === 0 && currentStep === 4 && !isLoading) {
      setCurrentStep(2);
    } else {
      setCurrentStep((pre) => pre - 1);
    }
    if (infoList.length === currentStep + 1) {
      setInfoList(infoList.slice(0, infoList.length - 1));
    }
    setIsLoading(true);
  };

  const handleConfirmStep = () => {
    setIsConfirmStep((prev) => !prev);
  };

  const handleResetInfo = () => {
    setCurrentStep(0);
    setInfoList([]);
    setIsConfirmStep(false);
  };

  return (
    <InfoContext.Provider
      value={{
        infoList,
        setInfoList,
        step,
        currentStep,
        setCurrentStep,
        allStep,
        setAllStep,
        handelNext,
        handelBack,
        priceInfo,
        setPriceInfo,
        total,
        handleConfirmStep,
        isConfirmStep,
        handleResetInfo,
        isLoading,
        getQueries,
        fetchData,
        newSteps,
        isTimeLoading,
        setIsTimeLoading,
        scrollRef,
        scrollLeft,
        scrollRight,
        setTenantId,
        tenantId,
      }}
    >
      {children}
    </InfoContext.Provider>
  );
};

export default InfoProvider;
