import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  InputAdornment,
  TextField,
  Slider,
  Autocomplete,
} from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import H1Style from '../../../components/textComponents/H1Style';
import PStyle from '../../../components/textComponents/PStyle';
import H6Style from '../../../components/textComponents/H6Style';
import CustomTextField from '../../../components/CustomTextField';
import CustomAutocomplete from '../../../components/CustomAutocomplete';
import SearchIcon from '@mui/icons-material/Search';

// import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <PStyle
      sx={{
        my: '5px',
      }}
    >
      {isReadMore ? text.slice(0, 50) : text}
      <span onClick={toggleReadMore}>
        {isReadMore ? (
          <>
            ...
            <span style={{ color: '#1976D2', cursor: 'pointer' }}>
              Read more
            </span>
          </>
        ) : (
          <span style={{ color: '#1976D2', cursor: 'pointer' }}>
            {' '}
            Show less
          </span>
        )}
      </span>
    </PStyle>
  );
};

// const FilterComp = ({ isDialog = true }) => {
//   return (
//     <Grid
//       md={3}
//       sx={{
//         display: !isDialog && { xs: "none", sm: "none", md: "block" },
//       }}
//     >
//       <H6Style sx={{ mb: "10px" }}>CATEGORY</H6Style>
//       <FormGroup>
//         <FormControlLabel
//           control={<Checkbox />}
//           label="Pizza prod"
//           sx={{
//             ".MuiFormControlLabel-label": {
//               fontSize: "14px",
//             },
//             height: "32px",
//           }}
//         />
//         <FormControlLabel
//           control={<Checkbox />}
//           label="Category 2"
//           sx={{
//             ".MuiFormControlLabel-label": {
//               fontSize: "14px",
//             },
//             height: "32px",
//           }}
//         />
//         <FormControlLabel
//           control={<Checkbox />}
//           label="Category 3"
//           sx={{
//             ".MuiFormControlLabel-label": {
//               fontSize: "14px",
//             },
//             height: "32px",
//           }}
//         />
//         <FormControlLabel
//           control={<Checkbox />}
//           label="Category 4"
//           sx={{
//             ".MuiFormControlLabel-label": {
//               fontSize: "14px",
//             },
//             height: "32px",
//           }}
//         />
//         <FormControlLabel
//           control={<Checkbox />}
//           label="Category 5"
//           sx={{
//             ".MuiFormControlLabel-label": {
//               fontSize: "14px",
//             },
//             height: "32px",
//           }}
//         />
//         <FormControlLabel
//           control={<Checkbox />}
//           label="Category 6"
//           sx={{
//             ".MuiFormControlLabel-label": {
//               fontSize: "14px",
//             },
//             height: "32px",
//           }}
//         />
//       </FormGroup>
//     </Grid>
//   );
// };

const FilterComp = ({ isDialog = true, prodsData, setProdsData, data }) => {
  const [categoryFilter, setCategoryFilter] = useState('');
  const [categories, setCategories] = useState([]);
  const [value, setValue] = React.useState(0);
  // const [keywordFilter, setKeywordFilter] = useState('');
  const keywordFilter = useRef(null);
  const priceFilter = useRef(null);

  function SelectCategory({ selectedValue, setSelectedValue, sx, multiple }) {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;
    let headersList = {
      Accept: '*/*',
    };
    const UpnizeBackend = axios.create({
      baseURL: `${process.env.REACT_APP_UPNIZE_BACKEND_URL}/tenant`,
      headers: headersList,
      withCredentials:
        process.env.DEPLOY_TARGET === 'production' ? true : false,
    });
    const getCategoryList = async (rows, page) => {
      let response = await UpnizeBackend.get(
        `/categories/products?Rows=${rows}&page=${page}`,
        {
          headers: {
            TenantId: data.TenantId,
          },
        }
      );
      return response;
    };

    const handleOpen = () => {
      getCategoryList(100, 1)
        .then((res) => res.data)
        .then((ser) => {
          setOptions(ser);
        });
    };

    React.useEffect(() => {
      if (!open) {
        // setOptions([]);
      } else {
        handleOpen();
      }
    }, [open]);

    return (
      <Autocomplete
        sx={{ width: '100%', ...sx }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        multiple={multiple}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        options={options}
        loading={loading}
        value={selectedValue}
        onChange={(e, v) => {
          setSelectedValue(v);
        }}
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Select category"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    );
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleCategoryFilter = (e, newValue) => {
    setCategoryFilter((prevState) => newValue);
  };
  const handleKeywordFilter = (e) => {
    keywordFilter.current = e.target.value;
    console.log('keywordFilter', keywordFilter);
    let filteredProd = [];
    // if (keywordFilter.current.length > 0) {
    for (let i = 0; i < data.products.length; i++) {
      if (
        data.products[i].title
          .toLowerCase()
          .includes(keywordFilter.current.toLowerCase()) &&
        data.products[i].salePrice <= priceFilter.current
      ) {
        filteredProd = [...filteredProd, data.products[i]];
      }
      setProdsData(filteredProd);
    }
    // } else {
    // setProdsData(data.products);
    // }
  };
  const handlePriceFilter = (e) => {
    priceFilter.current = e.target.value;
    console.log(priceFilter);
    let filteredProd = [];
    // if (priceFilter.current !== 400) {
    for (let i = 0; i < data.products.length; i++) {
      if (
        data.products[i].salePrice <= priceFilter.current &&
        data.products[i].title
          .toLowerCase()
          .includes(keywordFilter.current.toLowerCase())
      ) {
        filteredProd = [...filteredProd, data.products[i]];
      }
    }
    setProdsData(filteredProd);
    // } else {
    // setProdsData(data.products);
    // }
  };

  const filterProds = (e) => {
    keywordFilter.current = e.target.value;
    // priceFilter.current = e.target.value;
    let filteredProd = [];
    if (keywordFilter.current.length > 0) {
      prodsData.forEach((item) => {
        if (keywordFilter.current.length === 0) {
          filteredProd = data.products;
          setProdsData(filteredProd);
        }
        if (
          item.title.toLowerCase().includes(keywordFilter.current.toLowerCase())
        ) {
          filteredProd = [...filteredProd, item];
          setProdsData(filteredProd);
        }
      });
    } else {
      setProdsData(data.products);
    }
  };

  const [selectedValue, setSelectedValue] = useState(null);

  return (
    <Grid
      sx={{
        display: !isDialog && { xs: 'none', sm: 'none', md: 'block' },
        width: '350px',
        minWidth: { xs: '100%', md: 'auto' },
        justifyContent: 'center',
      }}
    >
      <Box>
        <CustomTextField
          onChange={handleKeywordFilter}
          ref={keywordFilter}
          customData={{
            label: 'Keyword',
            // labelSx: { fontSize: "14px" },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon sx={{ color: '#D9DAE2' }} />
              </InputAdornment>
            ),
          }}
          sx={{ mb: 1 }}
        ></CustomTextField>
      </Box>
      <Box sx={{ mb: 3 }}>
        <Typography
          sx={{
            fontSize: '18px',
            color: '#1976D2',
            fontWeight: 700,
          }}
        >
          Price
        </Typography>
        <Slider
          ref={priceFilter}
          onChange={handlePriceFilter}
          defaultValue={400}
          max={400}
          aria-label="Default"
          valueLabelDisplay="auto"
        />
      </Box>
      <SelectCategory
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
      />
      {/* <Box>
        <CustomAutocomplete
          value={categoryFilter}
          onChange={handleCategoryFilter}
          options={categories.map((category) => category.label)}
          customData={{
            label: 'Category',
            // labelSx: { fontSize: "14px" },
          }}
          renderInput={(params) => <TextField {...params} />}
          sx={{ mb: 3 }}
        />
      </Box> */}
    </Grid>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Product = ({ data }) => {
  const [open, setOpen] = React.useState(false);
  const [prodsData, setProdsData] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [numOfProds, setNumOfProds] = useState(6);
  const showMore = () => {
    setNumOfProds((prev) => prev + 3);
  };
  // const prods = products.slice(0, numOfProds);
  const appBarHeight = '95px';

  useEffect(() => {
    setProdsData(data.products);
    console.log('data.products', data.products);
  }, []);

  return (
    <Grid
      item
      container
      sx={{
        minWidth: { xs: '100%', md: 'auto' },
        justifyContent: 'center',
      }}
    >
      <Dialog
        fullScreen
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          zIndex: 998,
          top: `${appBarHeight} !important`,
        }}
      >
        <DialogContent>
          <FilterComp
            isDialog={true}
            setProdsData={setProdsData}
            prodsData={prodsData}
            data={data}
          />
        </DialogContent>
      </Dialog>
      <Button
        onClick={() => setOpen(!open)}
        variant="contained"
        sx={{
          width: '150px',
          position: 'fixed',
          bottom: '20px',
          left: '0',
          right: '0',
          mx: 'auto',
          display: { xs: 'flex', sm: 'flex', md: 'none' },
          gap: '5px',
          zIndex: '9999 !important',
        }}
      >
        {open ? <CloseIcon /> : <FilterAltIcon />}
        FILTER
      </Button>
      <div className="body__container">
        <H1Style
          sx={{
            // color: "#1976D2",
            // fontWeight: "bold",
            // fontSize: "18px",
            marginBottom: '20px',
          }}
        >
          Our product
        </H1Style>
        <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box
            md={9}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: '1 1 auto',
            }}
          >
            {/* <H1Style
            sx={{
              // color: "#1976D2",
              // fontWeight: "bold",
              // fontSize: "18px",
              marginBottom: "20px",
            }}
          >
            Our product
          </H1Style> */}
            <Grid
              display="flex"
              justifyContent={{
                xs: 'center',
                sm: 'flex-start',
                md: 'flex-start',
              }}
              flexWrap="wrap"
              gap="35px"
            >
              {prodsData?.map((product) => (
                <Box
                  sx={{
                    // width: "300px",
                    maxWidth: '300px',
                    minHeight: '300px',
                  }}
                >
                  <Box
                    sx={{
                      width: '300px',
                      height: '300px',
                      boxShadow:
                        'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em',
                    }}
                  >
                    <img
                      alt=""
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                      src={
                        product.image
                          ? `${process.env.REACT_APP_UPNIZE_BACKEND_URL}/${product.image}`
                          : 'https://www.arma.org/global_graphics/default-store-350x350.jpg'
                      }
                    />
                  </Box>

                  <H6Style
                    sx={{
                      // fontWeight: "bold",
                      color: '#3E3B4E',
                      mt: '20px',
                      mb: '5px',
                    }}
                  >
                    {product.title}
                  </H6Style>

                  {product.description.length > 50 ? (
                    <ReadMore>{product.description}</ReadMore>
                  ) : (
                    product.description
                  )}
                  <Box
                    sx={{
                      display: 'flex',
                      // justifyContent: "center",
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <Typography
                      sx={{
                        mt: '10px',
                        fontWeight: 'bold',
                        fontSize: '15px',
                        textDecoration: 'line-through',
                      }}
                    >
                      $ {product.salePrice}
                    </Typography>
                    <Typography
                      sx={{
                        mt: '10px',
                        fontWeight: 'bold',
                        fontSize: '20px',
                        color: '#EA1B00',
                      }}
                    >
                      $ {product.offerPrice}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Grid>
            {!(numOfProds >= prodsData.length) && (
              <Button
                onClick={showMore}
                variant="outlined"
                sx={{ width: 'max-content', alignSelf: 'center', mt: '50px' }}
              >
                Show more products
              </Button>
            )}
          </Box>
          <Box
            xs={12}
            md={3}
            sx={{
              flex: '0 0 350px',
              display: { xs: 'none', sm: 'none', md: 'block' },
            }}
          >
            <FilterComp
              isDialog={false}
              setProdsData={setProdsData}
              prodsData={prodsData}
              data={data}
            />
          </Box>
        </Grid>
      </div>
    </Grid>
  );
};

export default Product;
