import CookieConsent from "react-cookie-consent";
import { useNavigate } from "react-router-dom";
import classes from "./CookiePopup.module.css";

const CookiePopup = () => {

    const navigate = useNavigate()

    const handleNavigation = () => {
        navigate("/cookie")
    }

    return (
        <div>
            <CookieConsent>This website uses cookies to enhance the user experience. <button onClick={handleNavigation} className={classes.learnMoreBtn} style={{ fontSize: "10px" }}>Learn More...</button></CookieConsent>
        </div>
    )
}

export default CookiePopup;