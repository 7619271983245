import { Box, Grid, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import useCalendar from './hook/useCalendar';
import Calendar from './Calendar';
import useGetInfo from './hook/useGetInfo';
import CircularProgress from '@mui/material/CircularProgress';

const DateTimeOption = ({ options, stepId, isDialog }) => {
  const { isLoading } = useGetInfo();
  const { isTimeLoading, setIsTimeLoading } = useGetInfo();

  // const [isLoading, setIsLoading] = useState(false);

  const { infoList, setInfoList } = useGetInfo();
  const info = infoList.find((info) => info.id === stepId);
  const { selectedDate, monthNames } = useCalendar();
  const [month, setMonth] = useState(selectedDate.getMonth());
  const [day, setDay] = useState();
  const [isTime, setisTime] = useState(false);
  const [time, setTime] = useState();
  const dateClickHandler = (date) => {
    const data = date.split('-');
    setMonth(data[1]);
    setDay(data[0]);
    setisTime(true);
    if (info) {
      const userInfo = infoList.map((inf) => {
        if (inf.id === stepId) {
          inf = { ...info, date: date, type: 'Date' };
        }
        return inf;
      });
      setInfoList(userInfo);
    } else {
      const data = { id: stepId, date: date, time: time, type: 'Date' };
      setInfoList([...infoList, data]);
    }
  };

  useEffect(() => {
    if (info) {
      setisTime(true);
      setTime(info.time);
      setDay(info.date);
      setIsTimeLoading(false);
    }
  }, [info]);

  const handelTime = (time) => {
    setTime(time);
    // setIsTimeLoading(true);
    if (info) {
      const userInfo = infoList.map((inf) => {
        if (inf.id === stepId) {
          inf = { ...info, time: time, type: 'Date' };
        }
        return inf;
      });
      setInfoList(userInfo);
    } else {
      const data = { id: stepId, time: time, type: 'Date' };
      setInfoList([...infoList, data]);
    }
  };
  console.log(time);
  return (
    <>
      {isLoading ? (
        <Box
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexWrap: !isDialog ? 'wrap' : '',
            overflow: !isDialog ? 'scroll' : '',

            flexDirection: isDialog && {
              xs: 'column',
              sm: 'column',
              md: 'row',
              lg: 'row',
            },
            // overflow: { md: "hidden" },
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
              width: '0px',
            },
            '&::-webkit-scrollbar-track': {
              '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,.1)',
              // outline: '1px solid slategrey'
            },
          }}
        >
          <Box
            sx={{
              width: isDialog
                ? { xs: '100%', sm: '100%', md: '40%', lg: '40%' }
                : '100%',
              height: '26rem',
            }}
          >
            <Calendar dateClickHandler={dateClickHandler} stepId={stepId} />
          </Box>
          <Box
            sx={{
              width: isDialog
                ? { xs: '100%', sm: '100%', md: '60%', lg: '60%' }
                : '100%',
              marginTop: { xs: '2rem', md: '1.2rem' },
            }}
          >
            <Typography
              variant="h4"
              color="black"
              fontWeight="bold"
              fontSize="16px"
              textAlign="center"
            >
              {day ? 'Peek Appointment Time For' : 'Select a date'}
              {day && (
                <span style={{ color: '#4B64E9' }}>
                  {' '}
                  {monthNames[month - 1] + ' ' + day.split('-')[0]}
                </span>
              )}
            </Typography>

            {isTimeLoading ? (
              <Box
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Grid
                container
                spacing={3}
                sx={{
                  position: 'relative',
                  top: 73,
                  left: 40,
                  width: '100%',
                  // width: { xs: "100%", sm: "117%", md: "117%", lg: "117%" },
                }}
              >
                {isTime ? (
                  <>
                    {Array.isArray(options) ? (
                      options.map(
                        (option, index) =>
                          index !== options.length - 1 && (
                            <Grid
                              key={option}
                              sx={{
                                background:
                                  `${option.from} - ${option.to}` === time
                                    ? '#4B64E9'
                                    : option.available
                                    ? '#D9FEC9'
                                    : '#f4f5f9',
                                padding: '10px',
                                margin: '6px',
                                color:
                                  `${option.from} - ${option.to}` === time
                                    ? 'white'
                                    : 'black',
                                cursor: 'pointer',
                                borderRadius: '4px',
                                textAlign: 'center',
                                fontWeight: 'bold',
                                fontSize: '12px',
                              }}
                              xs={11}
                              sm={3}
                              md={3}
                              onClick={() =>
                                handelTime(`${option.from} - ${option.to}`)
                              }
                            >
                              {`${option.from} - ${option.to}`}
                            </Grid>
                          )
                      )
                    ) : (
                      <Typography
                        sx={{
                          color: '#939AB5',
                          fontSize: '20px',
                        }}
                      >
                        No times available found
                      </Typography>
                    )}{' '}
                  </>
                ) : (
                  ''
                )}
              </Grid>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default DateTimeOption;
