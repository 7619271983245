import { Box, Checkbox, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import useGetInfo from "./hook/useGetInfo";

const LocationOption = ({ options, handelClick, stepId, isChecked }) => {
  // const [isLoading, setIsLoading] = useState(false);
  const { isLoading } = useGetInfo();
  useEffect(() => {
    // checkAnsCollaps();
    // console.log("optionsData", optionsData);
    console.log("***********");
  }, []);

  return (
    <>
      {isLoading ? (
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {options.map((opteions, index) => (
            <Box
              key={index}
              onClick={() => handelClick(opteions, stepId, "location")}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "4px 20px",
                border: "1px solid #ddd",
                margin: "10px",
                height: "60px",
                cursor: "pointer",
              }}
            >
              <Typography variant="p">{opteions.name}</Typography>
              <Checkbox
                sx={{ hight: "80px" }}
                checked={isChecked(opteions, stepId)}
                classes={{ root: "checkbox" }}
              />
            </Box>
          ))}
        </>
      )}
    </>
  );
};

export default LocationOption;
