import { Box } from "@mui/material";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { getDocsAPI } from "../../../services";
import Highlighted from "../../Highlighted";
import H1Style from "../../textComponents/H1Style";
import HelpCenterSearch from "../Search";
import classes from "./HelpCenterWelcomeSection.module.css";

const HelpCenterWelcomeSection = () => {
  const [list, setList] = useState([]);
  const [query, setQuery] = useState("");
  const [expanded, setExpanded] = useState(false);

  const getDocsFromAPI = useCallback(async () => {
    try {
      const response = await getDocsAPI();
      setList(response.data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getDocsFromAPI();
  }, [getDocsFromAPI]);

  const filteredItems = list.filter((doc) =>
    doc.title.toLowerCase().includes(query.toLowerCase())
  );

  function expand() {
    setExpanded(true);
  }

  function close(e) {
    console.log(e.relatedTarget);
    if (
      !document
        .getElementById("help-center-search-dropdown")
        .contains(e.relatedTarget)
    ) {
      setExpanded(false);
    }
  }

  const handleSearchValue = (e) => {
    setQuery(e.target.value);
  };

  const handleClearSearch = () => {
    setQuery("");
  };

  const dropDownListStyle = `${classes.dropdownList} ${
    query && expanded ? classes.visible : ""
  }`;

  return (
    <Box
      className={classes.welcomeSection}
      sx={{
        paddingLeft: {
          xs: "16px !important",
          sm: "40px !important",
          md: "40px !important",
        },
        paddingRight: {
          xs: "16px !important",
          sm: "40px !important",
          md: "40px !important",
        },
      }}
    >
      <div className={classes.pageHeader}>
        <H1Style>Get Help</H1Style>
      </div>
      <div className={classes.searchContainer} sx={{}}>
        <HelpCenterSearch
          placeholder="Search"
          value={query}
          handleClearSearch={handleClearSearch}
          onFocus={expand}
          onBlur={close}
          onChange={handleSearchValue}
        />
        <div
          id="help-center-search-dropdown"
          tabIndex="0"
          className={dropDownListStyle}
        >
          <div className={classes.dropDownListContentHolder}>
            <span>Help Center</span>
            {filteredItems.length > 0 &&
              filteredItems.map((doc, index) => (
                <Link
                  to={`/documentation/${doc.id}`}
                  className="neutralize-link"
                >
                  <div className={classes.elementContainer}>
                    <Highlighted
                      key={index}
                      text={doc.title}
                      highlight={query}
                    />
                  </div>
                </Link>
              ))}
            {filteredItems.length === 0 && (
              <Link to="/contact-support" className="neutralize-link">
                <div className={classes.elementContainer}>
                  <span className={classes.helpFromSupport}>
                    Get help from support
                  </span>
                </div>
              </Link>
            )}
          </div>
        </div>
      </div>
    </Box>
  );
};

export default HelpCenterWelcomeSection;
