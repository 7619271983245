import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import Login from "./Login";
import Register from "./Register";
import Google from "../../assets/img/social/google.png";
import Facebook from "../../assets/img/social/facebook.png";
import useGetInfo from "./hook/useGetInfo";
import CircularProgress from "@mui/material/CircularProgress";

const Auth = ({ stepId }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { step, allStep, setAllStep } = useGetInfo();

  const handelLogin = (bool) => {
    if (bool) {
      const data = allStep.map((step) =>
        step.id === stepId ? { ...step, isLogin: true } : step
      );
      setAllStep(data);
    } else {
      const data = allStep.map((step) =>
        step.id === stepId ? { ...step, isLogin: false } : step
      );
      setAllStep(data);
    }
  };

  return (
    <>
      {isLoading ? (
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ borderBottom: "1px solid #ddd", overflow: { md: "hidden" } }}
          >
            <Typography
              sx={{ padding: "5px", fontWeight: "600", cursor: "pointer" }}
              borderBottom={!step.isLogin && "3px solid blue"}
              color={!step.isLogin ? "#000" : "#ddd"}
              onClick={() => handelLogin(false)}
            >
              Sign Up
            </Typography>
            <Typography
              sx={{ padding: "5px", fontWeight: "600", cursor: "pointer" }}
              borderBottom={step.isLogin && "3px solid blue"}
              color={step.isLogin ? "#000" : "#ddd"}
              onClick={() => handelLogin(true)}
            >
              Sign In
            </Typography>
          </Box>
          {step.isLogin ? <Login /> : <Register />}
          {/* <Box
            display="flex"
            justifyContent="center"
            sx={{ alignItems: "center" }}
          >
            <Box width="50px" sx={{ height: "1px", background: "blue" }} />
            <Typography color="primary" variant="h6" padding="6px">
              Or
            </Typography>
            <Box width="50px" sx={{ height: "1px", background: "blue" }} />
          </Box>
          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              sx={{ flexDirection: { xs: "column", sm: "row" } }}
            >
              <Button variant="outlined" fullWidth sx={{ height: "45px" }}>
                <img src={Facebook} alt="facebook" />
                <Typography fontWeight="bold">logIn With Facebook</Typography>
              </Button>
              <Box padding="10px" />
              <Button
                variant="outlined"
                fullWidth
                sx={{ border: "1px solid #bebebe", height: "45px" }}
              >
                <img src={Google} alt="facebook" />
                <Typography fontWeight="bold" sx={{ color: "#bebebe" }}>
                  logIn With Google
                </Typography>
              </Button>
            </Box>
            <Box display="flex" justifyContent="space-between"></Box>
          </Box> */}
        </Box>
      )}
    </>
  );
};

export default Auth;
