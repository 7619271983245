import { useRoutes } from 'react-router-dom';
import System from './components/System';
import Subscribe from './pages/Subscribe';
import NotFound from './pages/NotFound';
import Pricing from './pages/Pricing';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import StartPage from './pages/Startpage';
import Marketplace from './pages/Marketplace';
import ContactSupport from './pages/Support';
import NewsList from './pages/newslist';
import News from './pages/News';
import Company from './pages/Company';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Features from './pages/Features';
import HelpCenter from './pages/HelpCenter';
import Documentation from './pages/Documentation';
import TermsOfService from './pages/TermsOfService';
import Cookie from './pages/Cookie';
import ChatSupport from './pages/ChatSupport';
import Products from './pages/Products';
import InfoProvider from './components/BookingWidget/context/InfoProvider';

export const Routes = () => {
  const element = useRoutes(routes);
  return element;
};

const routes = [
  {
    path: '/',
    element: (
      <System>
        <StartPage />
      </System>
    ),
  },
  {
    path: '/subscribe',
    element: (
      <System>
        <Subscribe />
      </System>
    ),
  },
  // {
  //   path: "/pricing",
  //   element: (
  //     <System>
  //       <Pricing />
  //     </System>
  //   ),
  // },
  {
    path: '/blog',
    element: (
      <System>
        <Blog />
      </System>
    ),
  },
  {
    path: '/contact',
    element: (
      <System>
        <Contact />
      </System>
    ),
  },
  {
    path: '/documentation/:docId',
    element: (
      <System>
        <Documentation />
      </System>
    ),
  },
  {

    path: '/network',
    element: (
      <System>
        <Marketplace />
      </System>
    ),
  },
  {
    path: '/contact-support',
    element: (
      <System>
        <ContactSupport />
      </System>
    ),
  },
  {
    path: '/news',
    element: (
      <System>
        <NewsList />
      </System>
    ),
  },
  // {
  //   path: "/products",
  //   element: (
  //     <System>
  //       <Products />
  //     </System>
  //   ),
  // },
  {
    path: '/news/:newsId',
    element: (
      <System>
        <News />
      </System>
    ),
  },
  {
    path: '/features',
    element: (
      <System>
        <Features />
      </System>
    ),
  },

  {
    path: '/company/:id/:loctionId',
    element: (
      <System>
        <InfoProvider>
          <Company />
        </InfoProvider>
      </System>
    ),
  },

  {
    path: '/company/:id/:loctionId',
    element: (
      <System>
        <InfoProvider>
          <Company />
        </InfoProvider>
      </System>
    ),
  },
  {

    path: '/company/:id/:loctionId',
    element: (
      <System>
        <InfoProvider>
          <Company />
        </InfoProvider>
      </System>
    ),
  },
  {

    path: '/help-center',
    element: (
      <System>
        <HelpCenter />
      </System>
    ),
  },
  {
    path: '/chat-support',
    element: (
      <System>
        <ChatSupport />
      </System>
    ),
  },
  {
    path: '/privacy-policy',
    element: (
      <System>
        <PrivacyPolicy />
      </System>
    ),
  },
  {
    path: '/cookie',
    element: (
      <System>
        <Cookie />
      </System>
    ),
  },
  {
    path: '/terms-of-service',
    element: (
      <System>
        <TermsOfService />
      </System>
    ),
  },
  {
    path: '*',
    element: (
      <System>
        <NotFound />
      </System>
    ),
  },
];
