import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CustomTypography from "../../components/CustomTypography";
import CustomTextField from "../../components/CustomTextField";
import { Divider, TextField } from "@mui/material";
import CustomAutocomplete from "../../components/CustomAutocomplete";
import CustomButton from "../../components/CustomButton";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import classes from "./RegisterToBeNotified.module.css";
import { addSubscriberAPI } from "../../services";
import { goUpThePage } from "../../utils/goUpThePage";

const RegisterToBeNotified = ({
  countriesList,
  citiesList,
  industriesList,
  handleCitiesList,
}) => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    type: "user",
    industry: [],
    email: "",
    country: { Name: "", Code: "" },
    city: "",
    firstName: "",
    lastName: "",
  });
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (form?.country?.Code) {
      handleCitiesList(form?.country?.Code);
    }
  }, [form?.country?.Code]);

  const handleTextField = (fieldName) => (e) => {
    setForm((prevState) => ({ ...prevState, [fieldName]: e.target.value }));
  };

  const handleAutoCompleteField = (fieldName) => (e, value) => {
    setForm((prevState) => ({ ...prevState, [fieldName]: value }));
  };

  const handleSubmit = async () => {
    try {
      setSuccess(false);
      await addSubscriberAPI({
        ...form,
        country: form?.country?.Name,
        industry: form?.industry
          ?.map((ind) => {
            return ind.Name;
          })
          .toString(),
      }).then((res) => {
        setForm({
          type: "register-to-be-notified",
          industry: [],
          email: "",
          country: { Name: "", Code: "" },
          city: "",
          firstName: "",
          lastName: "",
        });
        setSuccess(true);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleNavigation = (link) => () => {
    navigate(link);
    goUpThePage();
  };

  return (
    <Grid xs={12} md={6}>
      <Box>
        <div className={classes.forCompanies__formContainer}>
          <div className={classes.forCompanies__formContainer__form}>
            <CustomTypography
              sx={{
                fontWeight: "bold",
                fontSize: 14,
              }}
              textAlign={"center"}
            >
              Subscribe and get a 5-50% off when you booking first time.
            </CustomTypography>
            <CustomTypography
              sx={{
                fontWeight: "bold",
                fontSize: 35,
                mt: 4,
                mb: 3,
              }}
              textAlign={"center"}
              variant={"h2"}
            >
              Register to be notified
            </CustomTypography>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <Grid xs={11.5} ml={1} sx={{ mr: "auto", ml: "auto" }}>
                <CustomTextField
                  value={form.email}
                  onChange={handleTextField("email")}
                  customData={{
                    label: "Email",
                    labelSx: {
                      fontWeight: "normal",
                      fontSize: "16px",
                    },
                  }}
                  required
                  placeholder={"name@upnize.com"}
                />
              </Grid>

              <Grid
                xs={11.5}
                md={5.5}
                ml={1}
                sx={{ mr: "auto", ml: "auto", mt: 1 }}
              >
                <CustomTextField
                  value={form.firstName}
                  onChange={handleTextField("firstName")}
                  customData={{
                    label: "First name",
                    labelSx: {
                      fontWeight: "normal",
                      fontSize: "16px",
                    },
                  }}
                  required
                />
              </Grid>
              <Grid
                xs={11.5}
                md={5.5}
                ml={1}
                sx={{ mr: "auto", ml: "auto", mt: 1 }}
              >
                <CustomTextField
                  value={form.lastName}
                  onChange={handleTextField("lastName")}
                  customData={{
                    label: "Last name",
                    labelSx: {
                      fontWeight: "normal",
                      fontSize: "16px",
                    },
                  }}
                  required
                />
              </Grid>
              <Grid xs={11.5} md={5.5} sx={{ mr: "auto", ml: "auto", mt: 1 }}>
                <CustomAutocomplete
                  value={form.country}
                  onChange={handleAutoCompleteField("country")}
                  customData={{
                    label: "Select country",
                    labelSx: {
                      fontWeight: "normal",
                      fontSize: "16px",
                    },
                    textFieldProps: { placeholder: "Select..." },
                  }}
                  required
                  options={countriesList}
                  getOptionLabel={(country) => country?.Name || ""}
                />
              </Grid>
              <Grid xs={11.5} md={5.5} sx={{ mr: "auto", ml: "auto", mt: 1 }}>
                <CustomAutocomplete
                  value={form.city}
                  onChange={handleAutoCompleteField("city")}
                  customData={{
                    label: "Select city",
                    labelSx: {
                      fontWeight: "normal",
                      fontSize: "16px",
                    },
                    textFieldProps: { placeholder: "Select..." },
                  }}
                  required
                  options={form?.country?.Code ? citiesList : []}
                />
              </Grid>
              <Grid xs={11.5} sx={{ mr: "auto", ml: "auto", mt: 2, mb: 3 }}>
                <CustomTypography>
                  I would like to be notified from this industry’s
                </CustomTypography>
              </Grid>

              <Grid xs={11.5} sx={{ mr: "auto", ml: "auto" }}>
                <CustomAutocomplete
                  value={form.industry}
                  onChange={handleAutoCompleteField("industry")}
                  customData={{
                    label: "Select industry",
                    labelSx: {
                      fontWeight: "normal",
                      fontSize: "16px",
                    },
                    textFieldProps: { placeholder: "Select..." },
                  }}
                  required
                  multiple
                  options={industriesList}
                  getOptionLabel={(industry) => industry?.Name || ""}
                />
              </Grid>

              <Grid
                xs={11.5}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  mr: "auto",
                  ml: "auto",
                }}
              >
                <CustomTypography sx={{ fontSize: "16px" }} mb={1} mt={4}>
                  By clicking submit, I acknowledge receipt of the upnize{" "}
                  <Link
                    className={classes.linkStyle}
                    to="/privacy-policy"
                    onClick={() => {
                      goUpThePage();
                    }}
                  >
                    Privacy policy
                  </Link>
                </CustomTypography>
                <CustomTypography sx={{ fontSize: "14px" }} mb={3} mt={1}>
                  {success
                    ? "Thank you for subscribing to our newsletter."
                    : " "}
                </CustomTypography>
                <CustomButton
                  onClick={handleSubmit}
                  size={"large"}
                  sx={{ width: "180px" }}
                >
                  submit
                </CustomButton>
              </Grid>
            </Box>
          </div>
        </div>
      </Box>
    </Grid>
  );
};

export default RegisterToBeNotified;
