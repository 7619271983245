import { Box, Button, InputAdornment, TextField } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import useGetInfo from "./hook/useGetInfo";
import Flag from "../../assets/img/flag.jpg";
import { MuiTelInput } from "mui-tel-input";
import axios from "axios";
import { BACKEND_URI_USERS } from "../../constants/endpoint";
// import { MuiTelInput, isValidPhoneNumber } from "mui-tel-input";

const Register = () => {
  const { step, allStep, setAllStep } = useGetInfo();

  const [number, setNumber] = React.useState("+46");
  const [isValid, setIsValid] = React.useState(true);

  const { handelNext } = useGetInfo();

  const handleChangePhone = (newValue) => {
    // setIsValid(isValidPhoneNumber(newValue));
    setIsValid(newValue);
    setNumber(newValue);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
  } = useForm();

  const submitForm = async (data) => {
    if (!isValid) return;
    const userInfo = { ...data, phoneNumber: number };
    try {
      // fatch POST request for register
      // if success user register then
      axios
        .post(`${BACKEND_URI_USERS}/add`, {
          firstName: data.fristName,
          lastName: data.lastName,
          password: data.password,
          email: data.email,
          phone: userInfo.phoneNumber,
          dob: "03/03/1990",
          gender: "Male",
          type: "Customer",
          belongsTo: "Barbaros",
          active: true,
        })
        .then((res) => {
          console.log("Add User", res);
          handelNext();
        })
        .catch((err) => {
          if (err.response.status === 409) {
            console.log(err.response.data);
            console.log("Exists");
            setError("email", {
              type: "server",
              message: err.response.data,
            });
          }
        });
    } catch (error) {
      // error handeling
      console.log(error.message);
    }
    console.log(userInfo);
  };

  const validationText = () => "You must entered a value";
  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ flexDirection: { xs: "column", sm: "row", lg: "row" } }}
      >
        <TextField
          sx={{
            width: { xs: "100%", sm: "49%", lg: "49%" },
            marginTop: "15px",
          }}
          placeholder="Frist Name"
          id="outlined-basic"
          variant="outlined"
          name="fristName"
          {...register("fristName", {
            required: validationText(),
          })}
          error={!!errors?.fristName}
          helperText={errors?.fristName ? errors.fristName.message : null}
          inputProps={{
            style: {
              height: "45px",
              padding: "0 10px",
            },
          }}
        />

        <TextField
          sx={{
            width: { xs: "100%", sm: "49%", lg: "49%" },
            marginTop: "15px",
          }}
          placeholder="Last name"
          id="outlined-basic"
          variant="outlined"
          {...register("lastName", {
            required: validationText(),
          })}
          error={!!errors?.lastName}
          helperText={errors?.lastName ? errors.lastName.message : null}
          inputProps={{
            style: {
              height: "45px",
              padding: "0 10px",
            },
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ flexDirection: { xs: "column", sm: "row", lg: "row" } }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "49%", md: "49%" },
            marginTop: "15px",
          }}
        >
          {/* <Typography>This is valid ? {isValid ? 'yes' : 'no'}</Typography> */}

          <MuiTelInput
            value={number}
            onChange={handleChangePhone}
            placeholder="Phone Number"
            variant="outlined"
            error={!isValid || !number.length}
            name="phoneNumber"
            helperText={isValid ? "" : "Your number is not valid"}
            sx={{
              width: { xs: "100%", sm: "100%" },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={Flag} alt="flag" style={{ marginRight: "3px" }} />{" "}
                  <span style={{ color: "#4E4E4E" }}>+44</span>
                </InputAdornment>
              ),
              style: {
                height: "45px",
                padding: "0 10px",
              },
            }}
          />
        </Box>
        <TextField
          sx={{
            width: { xs: "100%", sm: "49%", lg: "49%" },
            marginTop: "15px",
          }}
          placeholder="Email"
          id="outlined-basic"
          variant="outlined"
          type="email"
          {...register("email", {
            required: validationText(),
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email address",
            },
          })}
          error={!!errors?.email}
          helperText={errors?.email ? errors.email.message : null}
          inputProps={{
            style: {
              height: "45px",
              padding: "0 10px",
            },
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ flexDirection: { xs: "column", sm: "row", lg: "row" } }}
      >
        <TextField
          sx={{
            width: { xs: "100%", sm: "49%", lg: "49%" },
            marginTop: "15px",
          }}
          placeholder="Password"
          id="outlined-basic"
          variant="outlined"
          type="password"
          {...register("password", {
            required: validationText(),
          })}
          error={!!errors?.password}
          helperText={errors?.password ? errors.password.message : null}
          inputProps={{
            style: {
              height: "45px",
              padding: "0 10px",
            },
          }}
        />
        <TextField
          sx={{
            width: { xs: "100%", sm: "49%", lg: "49%" },
            marginTop: "15px",
          }}
          placeholder="Confirm Password"
          id="outlined-basic"
          variant="outlined"
          type="password"
          name="reTypePassword"
          {...register("reTypePassword", {
            required: validationText(),
            validate: (val) => {
              if (watch("password") !== val) {
                return "Your passwords do no match";
              }
            },
          })}
          error={!!errors?.reTypePassword}
          helperText={
            errors?.reTypePassword ? errors.reTypePassword.message : null
          }
          inputProps={{
            style: {
              height: "45px",
              padding: "0 10px",
            },
          }}
        />
      </Box>
      <Button
        variant="contained"
        type="submit"
        sx={{
          position: "absolute",
          right: { md: "23px", xs: "23px" },
          bottom: "17px",
          background: "#4B64E9",
          height: "45px",
          width: "145Px",
        }}
      >
        Sign Up
      </Button>
    </form>
  );
};

export default Register;
