import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CustomTypography from '../../components/CustomTypography';
import CustomAutocomplete from '../../components/CustomAutocomplete';
import {
  Button,
  Dialog,
  DialogContent,
  InputAdornment,
  Rating,
  Slide,
  TextField,
  Typography,
} from '@mui/material';
import resturant from '../../assets/images/subscribe/Restaurant-dinner-booking.jpg';
import barber from '../../assets/images/marketplace/barber.png';
import classes from './Marketplace.module.css';
import CustomTextField from '../../components/CustomTextField';
import SearchIcon from '@mui/icons-material/Search';
import CustomButton from '../../components/CustomButton';
import PlaceIcon from '@mui/icons-material/Place';
import Chip from '../../components/Chip';
import StarIcon from '@mui/icons-material/Star';
import H1Style from '../../components/textComponents/H1Style';
import H3Style from '../../components/textComponents/H3Style';
import PStyle from '../../components/textComponents/PStyle';
import H6Style from '../../components/textComponents/H6Style';
import CloseIcon from '@mui/icons-material/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useLocation } from 'react-router-dom';
import { BACKEND_URL_IMAGE } from '../../constants/endpoint';
import { getLocations } from '../../services';
import { useNavigate } from 'react-router-dom';

const duration = [
  {
    duration: '0 Seats',
  },
  {
    duration: '15 Seats',
  },
  {
    duration: '30 Seats',
  },
  {
    duration: '45 Seats',
  },
  {
    duration: '1 Seats',
  },
];

const results = [
  {
    title: 'Company name here',
    address: '{{Address location, City, Country}}',
    perks: [
      { perk: 'Up to 2 team member', description: 'perks have descriptions' },
      {
        perk: 'Up to 100 items',
        description: 'perks have descriptionssssssssss',
      },
    ],
    categories: ['Category', 'Second'],
    image: barber,
    reviews: 4,
    reviewsCount: 7,
  },
  {
    title: 'Company name here',
    address: '{{Address location, City, Country}}',
    perks: [
      { perk: 'Up to 2 team member', description: 'perks have descriptions' },
      {
        perk: 'Up to 100 items',
        description: 'perks have descriptionssssssssss',
      },
    ],
    categories: ['Category', 'Second'],
    image: resturant,
  },
  {
    title: 'Company name here',
    address: '{{Address location, City, Country}}',
    perks: [
      { perk: 'Up to 2 team member', description: 'perks have descriptions' },
      {
        perk: 'Up to 100 items',
        description: 'perks have descriptionssssssssss',
      },
    ],
    categories: ['Category', 'Second'],
    image: barber,
    reviewsCount: 99,
    reviews: 5,
  },
];

const FilterComp = ({ isDialog = true, setNetworkData, networkData }) => {
  const [keyword, setKeyword] = useState('');
  const [location, setLocation] = useState('');
  const [industry, setIndustry] = useState('');
  const searchKeyword = useRef('');
  const searchLocation = useRef('');
  const searchIndustry = useRef('');
  const sortBy = useRef('');
  // const [sortBy, setSortBy] = useState('');
  const handleOptionSelect = (event, newValue) => {
    // setSortBy(newValue);
    console.log(sortBy);
    if (sortBy === 'Alphabetical Order') {
      const res = [...networkData].sort((a, b) => (a.name > b.name ? 1 : -1));
      console.log(res);
      setNetworkData(res);
    } else if (sortBy === 'Country') {
      const res = [...networkData].sort((a, b) =>
        a.address > b.address ? 1 : -1
      );
      setNetworkData(res);
    }
  };

  return (
    // <Box
    //   sx={{
    //     mb: { xs: 4, xl: 0 },
    //     // width: "100%",
    //     // px: { xs: 2, sm: 0 },
    //     display: !isDialog && { xs: "none", sm: "none", md: "block" },
    //     width: "350px",
    //   }}
    // >
    <Box
      sx={{
        width: '350px',
        display: !isDialog && { xs: 'none', sm: 'none', md: 'block' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          // justifyContent: "flex-start",
          // width: { xs: "100%", md: "300px" },
          mt: { xs: 0, md: 0 },
          mr: { xs: 0, md: 2 },
          width: '100%',
          // mb: 3,
        }}
      >
        <H6Style
          sx={{
            // fontSize: 14,
            // color: "#1976D2",
            width: '50%',
            mb: '5px',
          }}
        >
          Sort by
        </H6Style>
        <CustomAutocomplete
          ref={sortBy}
          // onChange={handleOptionSelect}
          onChange={async (e, newVal) => {
            sortBy.current = newVal ? newVal : '';
            console.log(sortBy.current);
            if (sortBy.current === 'Sort on location a - z') {
              const res = [...networkData].sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
              );
              console.log(res);
              setNetworkData(res);
            } else if (sortBy.current === 'Sort on location z - a') {
              const res = [...networkData].sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1
              );
              setNetworkData(res);
            } else {
              const result = await getLocations(
                searchKeyword.current,
                searchLocation.current,
                searchIndustry.current
              );
              setNetworkData(result.data);
            }
          }}
          options={['Sort on location a - z', 'Sort on location z - a']}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  style: {
                    fontWeight: '700',
                    color: '#3A3A50',
                  },
                }}
                placeholder="Select..."
                sx={{ mb: 3 }}
              />
            );
          }}
        />
      </Box>
      <CustomTextField
        onChange={async (e) => {
          searchKeyword.current = e.target.value;
          const result = await getLocations(
            searchKeyword.current,
            searchLocation.current,
            searchIndustry.current
          );
          setNetworkData(result.data);
        }}
        // onChange={(e) => {
        //   setKeyword(e.target.value);
        //   searchKeyword.current = keyword;
        // }}
        ref={searchKeyword}
        customData={{ label: 'Keyword' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon
                sx={{ color: '#D9DAE2' }}
                // onClick={async (e) => {
                //   const result = await getLocations(
                //     keyword,
                //     location,
                //     industry
                //   );
                //   setNetworkData(result.data);
                // }}
              />
            </InputAdornment>
          ),
        }}
        sx={{ mb: 3 }}
      ></CustomTextField>
      <CustomAutocomplete
        ref={searchLocation}
        onChange={async (e, newVal) => {
          searchLocation.current = newVal ? newVal : '';
          console.log(searchLocation.current);
          const result = await getLocations(
            searchKeyword.current,
            searchLocation.current,
            searchIndustry.current
          );
          setNetworkData(result.data);
        }}
        // options={duration.map((option) => option.duration)}
        options={['Egypt']}
        customData={{ label: 'Location' }}
        renderInput={(params) => <TextField {...params} />}
        sx={{ mb: 3 }}
      />
      <CustomAutocomplete
        onChange={async (e, newVal) => {
          searchLocation.current = newVal ? newVal : '';
          console.log(searchIndustry.current);
          const result = await getLocations(
            searchKeyword.current,
            searchLocation.current,
            searchIndustry.current
          );
          setNetworkData(result.data);
        }}
        options={duration.map((option) => option.duration)}
        customData={{
          label: 'Category',
        }}
        renderInput={(params) => <TextField {...params} />}
        sx={{ mb: 3 }}
      />
      {/* <CustomButton sx={{ px: 7 }}>Filter</CustomButton> */}
    </Box>
    // </Box>
  );
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Marketplace = () => {
  const [title, setTitle] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  // const networkData = location.state;
  const [networkData, setNetworkData] = useState([]);

  const appBarHeight = '95px';

  const handleSort = (e) => {
    if (e.target.value === 'alphabeticalOrder') {
      console.log(e.target.value);
    }
  };

  useEffect(() => {
    const checkData = async () => {
      if (!location.state?.data) {
        const result = await getLocations('', '', '');
        setNetworkData(result.data);
        setTitle('Search on our Network');
      } else {
        setNetworkData(location.state.data);
        // setTitle(
        //   `Search on ${location.state.keyword && location.state.keyword} ${
        //     location.state.industry &&
        //     location.state.keyword &&
        //     location.state.industry
        //       ? `,` + location.state.industry
        //       : '' + location.state.industry
        //   } ${location.state.location && `,` + location.state.location}`
        // );
        if (
          location.state.industry === '' &&
          location.state.keyword === '' &&
          location.state.location === ''
        ) {
          setTitle('Search on our Network');
        } else {
          setTitle(
            `Search on ${
              location.state.keyword !== '' ? location.state.keyword : ''
            } ${
              location.state.industry &&
              location.state.keyword &&
              location.state.industry
                ? `with ` + location.state.industry
                : location.state.industry
                ? location.state.industry
                : ''
            } ${
              (location.state.location && location.state.keyword) ||
              (location.state.location && location.state.industry) ||
              (location.state.location &&
                location.state.industry &&
                location.state.keyword)
                ? `in ` + location.state.location
                : // :  location.state.location
                location.state.location
                ? location.state.location
                : ''
            } `
          );
        }
      }
      console.log(networkData);
    };
    checkData();
  }, []);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid
      item
      container
      sx={{
        minWidth: { xs: '100%', md: 'auto' },
        justifyContent: 'center',
        paddingLeft: {
          xs: '16px !important',
          sm: '40px !important',
          md: '40px !important',
        },
        paddingRight: {
          xs: '16px !important',
          sm: '40px !important',
          md: '40px !important',
        },
      }}
    >
      {/* first section */}
      <Dialog
        fullScreen
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          zIndex: 998,
          top: `${appBarHeight} !important`,
        }}
      >
        <DialogContent>
          <FilterComp
            isDialog={true}
            setNetworkData={setNetworkData}
            networkData={networkData}
          />
        </DialogContent>
      </Dialog>
      <Button
        onClick={() => setOpen(!open)}
        variant="contained"
        sx={{
          width: '150px',
          position: 'fixed',
          bottom: '20px',
          left: '0',
          right: '0',
          mx: 'auto',
          display: { xs: 'flex', sm: 'flex', md: 'none' },
          gap: '5px',
          zIndex: 999,
        }}
      >
        {open ? <CloseIcon /> : <FilterAltIcon />}
        FILTER
      </Button>
      <div className="body__container">
        <Box
          sx={{
            display: 'flex',
            // justifyContent: "flex-start",
            alignItems: 'flex-start',
            width: '100%',
          }}
          mb={4}
        >
          <H1Style
            sx={{
              // fontWeight: "bold",
              marginTop: 12,
              marginBottom: 2,
              // fontSize: 50,
              // color: "#242424",
              // ml: { xs: 2, xl: 0 },
            }}
          >
            {title}
          </H1Style>
        </Box>
        <Grid
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            // mx: { xs: 2, md: 0 },
          }}
          mb={1}
        >
          <Box sx={{ display: 'flex' }}>
            <CustomTypography
              sx={{
                fontSize: 18,
                color: '#1976D2',
                fontWeight: '700',
              }}
            >
              {networkData.length}
            </CustomTypography>
            <CustomTypography
              sx={{
                fontSize: 18,
                color: '#1976D2',
                ml: 1,
              }}
            >
              result found
            </CustomTypography>
          </Box>
          {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: { xs: "100%", md: "300px" },
                  mt: { xs: 4, md: 0 },
                  mr: { xs: 0, md: 2 },
                }}
              >
                <CustomTypography
                  sx={{
                    fontSize: 14,
                    color: "#1976D2",
                    width: "50%",
                  }}
                >
                  SORTED BY
                </CustomTypography>
                <CustomAutocomplete
                  options={duration.map((option) => option.duration)}
                  defaultValue={"Most popular"}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            fontWeight: "700",
                            color: "#3A3A50",
                          },
                        }}
                        placeholder="Select..."
                      />
                    );
                  }}
                />
              </Box> */}
        </Grid>
        <Box
          sx={{
            display: 'flex',
            // flexWrap: "wrap",
            justifyContent: 'space-between',
          }}
        >
          <Box
            xs={12}
            md={9}
            sx={{
              flex: 'auto',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                // alignItems: "flex-start",
                // justifyContent: "flex-start",
              }}
            >
              {/* {results.map((resultInfo, i) => {
                const {
                  address,
                  title,
                  description,
                  categories,
                  image,
                  reviews,
                  reviewsCount,
                } = resultInfo;

                return (
                  <>
                    <Grid
                      xs={12}
                      sx={{
                        display: 'flex',
                        width: '100%',
                      }}
                    >
                      <Box
                        sx={{
                          p: { xs: '25px 16px 25px 0', md: '10px 16px 0 0' },
                          mb: i === 1 ? 0 : 4,
                          borderRadius: '6px',
                          width: '100%',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            backgroundColor: '#F7F8FB',
                          }}
                          mb={2}
                        >
                          <Box
                            sx={{
                              height: '250px',
                              width: 'fit-content',
                            }}
                          >
                            <img
                              src={image}
                              alt=""
                              className={classes.blog__image}
                            />
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                          }}
                        >
                          <H3Style
                            // fontWeight={900}
                            // fontSize={"30px"}
                            sx={
                              {
                                // width: "90%"
                              }
                            }
                            variant="h3"
                          >
                            {title}
                          </H3Style>
                          <CustomButton
                            sx={{
                              px: 1,
                              fontSize: '17px',
                              display: { md: 'flex', xs: 'none' },
                              // width: "195px",
                              // width: "max-content",
                              // wordWrap: "normal",
                            }}
                          >
                            book appointment
                          </CustomButton>
                        </Box>

                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <PStyle
                            // fontSize={"18px"}
                            mt={1}
                            mb={2}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              marginTop: '0px',
                            }}
                          >
                            <PlaceIcon sx={{ fontSize: '20px', mr: 1 }} />
                            {address}
                          </PStyle>
                          {reviews && (
                            <Box
                              sx={{
                                display: { md: 'flex', xs: 'none' },
                              }}
                              mt={2}
                            >
                              <Box>
                                {[...new Array(reviews)].map((rev) => {
                                  return (
                                    <StarIcon
                                      sx={{
                                        color: '#1976D2',
                                        fontSize: '18px',
                                      }}
                                    />
                                  );
                                })}
                              </Box>
                              <CustomTypography ml={3}>
                                {reviewsCount} Reviews
                              </CustomTypography>
                            </Box>
                          )}
                        </Box>

                        <Box sx={{ display: 'flex', mb: 2 }}>
                          {categories?.map((cate) => {
                            return <Chip>{cate}</Chip>;
                          })}
                        </Box>
                        {reviews && (
                          <Box
                            sx={{
                              display: { xs: 'flex', md: 'none' },
                            }}
                            mt={2}
                          >
                            <Box>
                              {[...new Array(reviews)].map((rev) => {
                                return (
                                  <StarIcon
                                    sx={{
                                      color: '#1976D2',
                                      fontSize: '18px',
                                    }}
                                  />
                                );
                              })}
                            </Box>
                            <CustomTypography ml={3}>
                              {reviewsCount} Reviews
                            </CustomTypography>
                          </Box>
                        )}
                        <CustomButton
                          sx={{
                            px: 1,
                            mt: 2,
                            fontSize: '17px',
                            display: { xs: 'flex', md: 'none' },
                          }}
                        >
                          book appointment
                        </CustomButton>
                      </Box>
                    </Grid>
                    {i === 1 && (
                      <Grid
                        xs={12}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: '#F7F8FB',
                          borderRadius: '14px',
                          p: 2,
                          my: 3,
                          mx: { xs: 2, xl: 0 },
                        }}
                      >
                        <CustomTypography
                          sx={{ fontWeight: '700', mr: 5, fontSize: '18px' }}
                        >
                          Start using UPNIZE today. Try it now for free / No
                          credit card needed
                        </CustomTypography>
                        <CustomButton
                          size={'large'}
                          sx={{
                            backgroundColor: '#979EEA',
                            fontSize: '12px',
                            py: 1.2,
                            ':hover': { backgroundColor: '#979EEA' },
                          }}
                        >
                          Get started
                        </CustomButton>
                      </Grid>
                    )}
                  </>
                );
              })} */}

              {networkData.map((resultInfo, i) => {
                const {
                  name,
                  headerImage,
                  address,
                  description,
                  Tenant,
                  TenantId,
                  id,
                  settings,
                  reviewsCount,
                  averageRating,
                } = resultInfo;

                return (
                  <>
                    <Grid
                      xs={12}
                      sx={{
                        display: 'flex',
                        width: '100%',
                      }}
                    >
                      <Box
                        sx={{
                          p: { xs: '25px 16px 25px 0', md: '10px 16px 0 0' },
                          mb: i === 1 ? 0 : 4,
                          borderRadius: '6px',
                          width: '100%',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            backgroundColor: '#F7F8FB',
                          }}
                          mb={2}
                        >
                          <Box
                            sx={{
                              height: '250px',
                              width: 'fit-content',
                            }}
                          >
                            <img
                              src={`${BACKEND_URL_IMAGE}${headerImage}`}
                              alt=""
                              className={classes.blog__image}
                            />
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                          }}
                        >
                          <H3Style
                            // fontWeight={900}
                            // fontSize={"30px"}
                            sx={{
                              // width: "90%"
                              cursor: 'pointer',
                            }}
                            variant="h3"
                            onClick={() => {
                              navigate(`/company/${TenantId}/${id}`);
                            }}
                          >
                            {name}
                          </H3Style>
                          <Box>
                            <CustomButton
                              disabled={settings.bookingWidget ? false : true}
                              sx={{
                                px: 1,
                                fontSize: '17px',
                                display: { md: 'flex', xs: 'none' },
                                // width: "195px",
                                // width: "max-content",
                                // wordWrap: "normal",
                              }}
                            >
                              book appointment
                            </CustomButton>
                            {reviewsCount ? (
                              <Box
                                sx={{
                                  display: 'flex',
                                  gap: '10px',
                                  mt: '10px',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Rating
                                  name="read-only"
                                  max={5}
                                  value={averageRating}
                                  readOnly
                                  icon={
                                    <StarIcon
                                      style={{ color: '#1976d2' }}
                                      fontSize="inherit"
                                    />
                                  }
                                />
                                <Typography>{reviewsCount} Reviews</Typography>
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  display: 'flex',
                                  gap: '10px',
                                  mt: '10px',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Rating
                                  name="read-only"
                                  max={5}
                                  value={0}
                                  readOnly
                                  icon={
                                    <StarIcon
                                      style={{ color: '#1976d2' }}
                                      fontSize="inherit"
                                    />
                                  }
                                />
                                <Typography>0 Reviews</Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <PStyle
                            // fontSize={"18px"}
                            mt={1}
                            mb={2}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              marginTop: '0px',
                            }}
                          >
                            <PlaceIcon sx={{ fontSize: '20px', mr: 1 }} />
                            {address}
                          </PStyle>
                          {/* {reviews && (
                            <Box
                              sx={{
                                display: { md: 'flex', xs: 'none' },
                              }}
                              mt={2}
                            >
                              <Box>
                                {[...new Array(reviews)].map((rev) => {
                                  return (
                                    <StarIcon
                                      sx={{
                                        color: '#1976D2',
                                        fontSize: '18px',
                                      }}
                                    />
                                  );
                                })}
                              </Box>
                              <CustomTypography ml={3}>
                                {reviewsCount} Reviews
                              </CustomTypography>
                            </Box>
                          )} */}
                        </Box>

                        <Box sx={{ display: 'flex', mb: 2 }}>
                          {/* {categories?.map((cate) => {
                            return <Chip>{cate}</Chip>;
                          })} */}
                          <Chip>{Tenant.industry}</Chip>
                        </Box>
                        {/* {reviews && (
                          <Box
                            sx={{
                              display: { xs: 'flex', md: 'none' },
                            }}
                            mt={2}
                          >
                            <Box>
                              {[...new Array(reviews)].map((rev) => {
                                return (
                                  <StarIcon
                                    sx={{
                                      color: '#1976D2',
                                      fontSize: '18px',
                                    }}
                                  />
                                );
                              })}
                            </Box>
                            <CustomTypography ml={3}>
                              {reviewsCount} Reviews
                            </CustomTypography>
                          </Box>
                        )} */}
                        <CustomButton
                          sx={{
                            px: 1,
                            mt: 2,
                            fontSize: '17px',
                            display: { xs: 'flex', md: 'none' },
                          }}
                        >
                          book appointment
                        </CustomButton>
                      </Box>
                    </Grid>
                    {i === 1 && (
                      <Grid
                        xs={12}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: '#F7F8FB',
                          borderRadius: '14px',
                          p: 2,
                          my: 3,
                          mx: { xs: 2, xl: 0 },
                        }}
                      >
                        <CustomTypography
                          sx={{ fontWeight: '700', mr: 5, fontSize: '18px' }}
                        >
                          Start using UPNIZE today. Try it now for free / No
                          credit card needed
                        </CustomTypography>
                        <CustomButton
                          size={'large'}
                          sx={{
                            backgroundColor: '#979EEA',
                            fontSize: '12px',
                            py: 1.2,
                            ':hover': { backgroundColor: '#979EEA' },
                          }}
                        >
                          Get started
                        </CustomButton>
                      </Grid>
                    )}
                  </>
                );
              })}
            </Box>
          </Box>
          <Box
            xs={12}
            md={3}
            sx={{
              display: { xs: 'none', sm: 'none', md: 'block' },
              flex: '0 0 350px',
            }}
          >
            <FilterComp
              setNetworkData={setNetworkData}
              networkData={networkData}
            />
          </Box>
        </Box>
      </div>
    </Grid>
  );
};

export default Marketplace;
