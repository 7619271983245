import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import AppLayout from '../AppLayout';
import Options from '../Options';
import axios from 'axios';
import { BACKEND_URI } from './../../../constants/endpoint';
import { getBooking } from '../../../services';
import { useParams } from 'react-router-dom';
import useGetInfo from '../hook/useGetInfo';

const BookingComponent = ({ step, isDialog, about }) => {
  const { tenantId } = useGetInfo();
  // const { id } = useParams();
  useEffect(() => {
    if (tenantId) {
      const fetchData = async () => {
        const res = await getBooking('', tenantId);
        // console.log("getCompany.....", res.data);
      };
      fetchData();
      console.log('TenantId --- ', tenantId);
    }
  }, [tenantId]);

  return (
    <AppLayout about={about} isDialog={isDialog}>
      <Box
        sx={{
          width: '100%',
          // height: "100%",
          height: isDialog ? { xs: 'calc(100vh - 300px)', sm: '100%' } : '100%',
        }}
      >
        <Options
          isDialog={isDialog}
          options={step.options}
          stepId={step.id}
          type={step.type}
        />
      </Box>
    </AppLayout>
  );
};

export default BookingComponent;
