import { Box, Button, TextField } from "@mui/material";
import useGetInfo from "./hook/useGetInfo";
import { useForm } from "react-hook-form";
import axios from "axios";
import { BACKEND_URI_USERS } from "../../constants/endpoint";
import { useState } from "react";

const Login = () => {
  const { handelNext } = useGetInfo();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const [user, setUser] = useState({});

  const submitForm = async (data) => {
    try {
      // fatch request POST for login
      // if success user login then
      axios
        .post(`${BACKEND_URI_USERS}/login`, {
          email: data.email,
          password: data.password,
        })
        .then((res) => {
          console.log("Login..", res);
          localStorage.setItem("user", JSON.stringify(res.data));
          const userData = JSON.parse(localStorage.getItem("user"));
          console.log(userData);
          handelNext();
        })
        .catch((err) => {
          if (err.response.status === 404) {
            console.log(err.response.data);
            console.log("Not Found");
            setError("email", {
              type: "server",
              message: err.response.data,
            });
          }
        });
    } catch (error) {
      // error handeling
    }
    console.log(data);
  };
  const validationText = () => "You must entered a value";
  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ flexDirection: { xs: "column", sm: "row", lg: "row" } }}
      >
        <TextField
          sx={{
            width: { xs: "100%", sm: "49%", lg: "49%" },
            marginTop: "15px",
          }}
          placeholder="Email"
          id="outlined-basic"
          variant="outlined"
          margin="=4px"
          name="email"
          {...register("email", {
            required: validationText(),
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email address",
            },
          })}
          error={!!errors?.email}
          helperText={errors?.email ? errors.email.message : null}
          inputProps={{
            style: {
              height: "45px",
              padding: "0 10px",
            },
          }}
        />
        <TextField
          sx={{
            width: { xs: "100%", sm: "49%", lg: "49%" },
            marginTop: "15px",
          }}
          id="outlined-basic"
          placeholder="Password"
          variant="outlined"
          name="password"
          type="password"
          {...register("password", {
            required: validationText(),
          })}
          error={!!errors?.password}
          helperText={errors?.password ? errors.password.message : null}
          inputProps={{
            style: {
              height: "45px",
              padding: "0 10px",
            },
          }}
        />
      </Box>
      <Button
        variant="contained"
        type="submit"
        sx={{
          position: "absolute",
          right: { md: "23px", xs: "23px" },
          bottom: "17px",
          background: "#4B64E9",
          height: "45px",
          width: "145Px",
        }}
      >
        Sign In
      </Button>
    </form>
  );
};

export default Login;
